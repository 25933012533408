import { useState } from "react";
import NoteContext from "./NoteContext";
import { useEffect } from "react";





const NoteState =(props)=>{
    const [token,setToken] = useState(() => {
        const storedToken = localStorage.getItem('token');
        return storedToken ? parseInt(storedToken) : "";
      });
      const [AgentToken,setAgentToken] = useState(() => {
        const storedAgentToken = localStorage.getItem('AgentToken');
        return storedAgentToken ? parseInt(storedAgentToken) : "";
      });
      const [name,setName] = useState(() => {
        const storedName = localStorage.getItem('name');
        return storedName ? parseInt(storedName) : "";
      });
      const [onBoardingID,setonBoardingID] = useState(() => {
        const storedonBoardingID = localStorage.getItem('onBoardingID');
        return storedonBoardingID ? parseInt(storedonBoardingID) : "";
      });
      const [type,setType] = useState(() => {
        const storedtype = localStorage.getItem('type');
        return storedtype ? parseInt(storedtype) : "";
      });

      const [sailor_info, setsailor_info] = useState([])
      const [update_agent, setUpdate_agent] = useState([])
      const [onboardingFlag, setonboardingFlag] = useState('')
      const [updatingFlag, setUpdatingFlag] = useState('')
      
    useEffect(() => {
    if (token) {
        localStorage.setItem('token', JSON.stringify(token));
    } else {
        localStorage.removeItem('token');
    }

    if (name) {
        localStorage.setItem('name', JSON.stringify(name));
    } else {
        localStorage.removeItem('name');
    }
    if (AgentToken) {
        localStorage.setItem('AgentToken', JSON.stringify(AgentToken));
    } else {
        localStorage.removeItem('AgentToken');
    }
    if (onBoardingID) {
        localStorage.setItem('onBoardingID', JSON.stringify(onBoardingID));
    } else {
        localStorage.removeItem('onBoardingID');
    }

    if (type) {
        localStorage.setItem('type', JSON.stringify(type));
    } else {
        localStorage.removeItem('type');
    }

    }, [token, name, AgentToken , onBoardingID,type ]);

   

    return(
        // <NoteContext.Provider value={{token,setToken, name,setName , AgentToken , setAgentToken , onBoardingID , setonBoardingID , type , setType , sailor_info, setsailor_info,setonboardingFlag,onboardingFlag}}>
        //     {props.children}
        // </NoteContext.Provider>
        <NoteContext.Provider value={{token,setToken, name,setName , AgentToken , setAgentToken , onBoardingID , setonBoardingID , type , setType , sailor_info, setsailor_info,setonboardingFlag,onboardingFlag, update_agent, setUpdate_agent, updatingFlag, setonboardingFlag}}>
            {props.children}
        </NoteContext.Provider>
    )
}

// export default NoteContext;
export default NoteState;