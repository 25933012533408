import React, { createContext, useContext, useState } from 'react';

const SessionContext = createContext();

export const useSession = () => {
  return useContext(SessionContext);
};

export const SessionProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('loggedIn') === 'true');

  const login = () => {
    localStorage.setItem('loggedIn', 'true');
    setLoggedIn(true);
  };

  const logout = () => {
    localStorage.setItem('loggedIn', 'false');
    setLoggedIn(false);
  };

  return (
    <SessionContext.Provider value={{ loggedIn, login, logout }}>
      {children}
    </SessionContext.Provider>
  );
};
