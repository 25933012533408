import React, { useState, useEffect, useContext } from "react";
import Topnav from "../../common/topNav";
import { Link } from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner"; 
import ELogo from "../../assets/images/sailor/logo.svg";
import ELogo1 from "../../assets/images/sailor/sailoronboard.svg";
import Mico1 from "../../assets/images/sailor/dash-icon.svg";
import Mico2 from "../../assets/images/sailor/boar-icon.svg";

import Ham from "../../assets/images/sailor/ham.svg";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Grid, TextField } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import tick from "../../assets/images/sailor/tick.png"
import passport_img from "../../assets/images/sailor/pass.jpg"
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormHelperText } from '@mui/material';
import AgentMenu from "../../components/AgentMenu";
import Navbar from "../Navbar";
import NoteContext  from "../../context/NoteContext";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { useParams, useNavigate  } from 'react-router-dom';
import Moment from 'react-moment';
import bank_verified from "../../assets/images/sailor/verified.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from "../../util/apiconfig";
import axios from 'axios';


function Viewonboard() {
  var FormData = require('form-data');
  const obj = useContext(NoteContext)
  const navigate = new useNavigate ();
  console.log("dasd",obj);
  if(obj.sailor_info.length === 0){
    if(obj.onboardingFlag === ""){
      navigate(-1)
    }
  }
  const [sailor_info, setsailor_info] = useState(obj.sailor_info)
  const [img, setImg] = useState()

  const [applicationStatus, setApplicantStatus] = useState()
  console.log("contesxt",sailor_info);
  const [ScreenView, setScreenView] = React.useState(obj.onboardingFlag);

  const handleChange = (event) => {
    const { name, value } = event.target;
  setApplicantStatus((prevStatus) => ({
    ...prevStatus,
    [name]: value
  }));
  };

  // handleChange(event) {
  //   event.preventDefault();
  //   this.setState({
  //     [event.target.name]: event.target.value,
  //   });
  // }

  useEffect(() => {
      fetchPhoto();
      findHistory();
  },[])
  const fetchPhoto = async() => {
    const response = {'userId': sailor_info._id}
    await axios.post(config.mongo_endpoint+"api/v1/sailorUser/viewPhotoByOnBoardingID", response, { headers: {   'Content-Type': 'application/x-www-form-urlencoded',} })
                  .then(result => {
                    console.log("photo",result.data);
                    if(result.status == 200){
                      setImg(config.imagePath+result.data);
                      
                    } 
                  })
                  // D:\Santhosh\Projects\aes-sailor-on-boarding\backend\app\uploadedImages
                  .catch(error => {
                    // handle the error
                    console.error(error);
                    if (error) {
                      
                      toast.error("Something went wrong. Please try again Later....", {
                      position: toast.POSITION.TOP_CENTER,
                      theme: "colored",
                    });
            
                    }
                  
                  });

   
    
  }

  const downloadDocument = async(onboardingId) => {

    await axios.get(config.mongo_endpoint+"api/v1/sailorUser/downloadfiles/user_"+onboardingId, {
        headers: {"Content-type": "application/json",} ,
          responseType: 'blob',
    })
    .then(function (response) {
        console.log("sailor",response);
        const blob = new Blob([response.data], { type: 'application/zip' });
        const blobUrl = URL.createObjectURL(blob);
  
        // Create a temporary link and click it to trigger the download
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'Sailor Documents.zip'; // Set the desired file name
        link.click();
  
        // Clean up the blob URL
        URL.revokeObjectURL(blobUrl);
        toast.success("Documents Downloaded Successfully.....", {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          });  
    })
    .catch(function (error) {
        console.log(error);
    });

}

  const [data , setData] = useState([])
    
  const findHistory = async() => {
    // let response = new FormData();viewPhotoByOnBoardingID
    // response.append('onBoardingID', '2');
      const response = {'onBoardingID': sailor_info.onBoardingID}
 

      await axios.post(config.mongo_endpoint+"api/v1/sailorUser/sailorHistory/findByOnboardingID", response, { headers: {  'Content-Type': 'application/x-www-form-urlencoded'} })
                  .then(result => {
                    console.log("fetch",result);
                    if(result.status == 200){
                      setData(result.data.data)
                    } 
                  })
                  .catch(error => {
                    // handle the error
                    console.error(error);
                    if (error) {
                      
                      toast.error("Something went wrong. Please try again Later....", {
                      position: toast.POSITION.TOP_CENTER,
                      theme: "colored",
                    });
            
                    }
                  
                  });
      
  }

  const handleDateChange = () => {
    
      const date = new Date();
      // Format the date to YYYY-MM-DD
      const formattedDate = date.toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
      }).split('/').reverse().join('-');
      return formattedDate;
    
   
  };
  const onSubmitInfo = async() => {
    
 
    
   const  request = {
    "Email":sailor_info.Email,
    "Application_Status":applicationStatus.status,
    "Update_Date": handleDateChange(),
    "Updated_By":`${localStorage.getItem('name')}`,
    "Commands":applicationStatus.comments,
  }
  

    try {
  
      await axios.post(config.mongo_endpoint+'api/v1/sailorUser/updatestatus', request, { headers: {  'Content-Type': 'application/x-www-form-urlencoded'} })
                  .then(result => {
                    console.log("fetch",result);
                    if(result.status == 200){
                     
                      toast.success("Status Updated Successfully.", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                      });  
                      
                      setTimeout(function() { navigate(-1); }, 3000);
                    } else {
                     
                      toast.success("Status Update Failed", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                      });  

                    }
                    
                    
                  })
                  .catch(error => {
                    // handle the error
                    console.error(error);
                    if (error) {
                      
                      toast.error("Something went wrong. Please try again Later....", {
                      position: toast.POSITION.TOP_CENTER,
                      theme: "colored",
                    });
            
                    }
                  
                  });


  } catch (error) {
    console.error(error);
    if (error) {
      alert("Something went wrong. Please try again Later....")
    }
  }

  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,

    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,

    },
  }));
  

  
  return (
    <>

      <AgentMenu></AgentMenu>

      <div class="container-fluid pr-0" id="main-area">
        <Navbar/>
        <div className="container-fluid">
          <div class="d-flex border-bottom pb-15 pt-15">
            <div class="me-auto ">
              <h2 class="page-title">View onboard</h2>
             
            </div>

          </div>
          <section className="voting-section">
            <div className="container">
              <div className="row">
                <div className="">
                <Box mb={3.5}>
                    <Grid container spacing={3}>
                      <Grid item sm={6} xs={12}>
                        <TableContainer component={Paper}>
                          <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Personal Information</StyledTableCell>
                                <StyledTableCell align="right"></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow sx={{ height: '50%' }}>
                                  <StyledTableCell component="th" scope="row">
                                    First Name
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.First_Name : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Middle Name
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.middle_name : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Last Name
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Last_Name : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Date of Birth
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? <Moment format="DD/MM/YYYY">
                                  {sailor_info.Date_of_Birth}
                                  </Moment>  : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Place of Birth
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Place_of_Birth : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Marital Status
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Maritial_Status : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Country
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Country : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Nationality
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Nationality : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Mobile No
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Phone : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Address of Communication
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Corresponding_Address : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Permanent Address
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Permanent_Address : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Emergency Contact Person
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Emergency_Contact_Person : ""}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Emergency Contact Number
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Emergency_Contact_Number : ""}</StyledTableCell>

                                </StyledTableRow>

                              </TableBody>
                          </Table>
                        </TableContainer>
                        <TableContainer component={Paper}>
                          <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Passport Info</StyledTableCell>
                                <StyledTableCell align="right"><img src={bank_verified} style={{ width: '100px' }} /></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Passport No
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Passport_Number : ""} </StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Place of Issue
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Place_Of_Issue : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Date Of Issue
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? <Moment format="DD/MM/YYYY">
                                  {sailor_info.Date_Of_Issue}
                                  </Moment> : ""}</StyledTableCell>

                                </StyledTableRow>

                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                  Valid Through
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Passport_Valid_Through ? <Moment format="DD/MM/YYYY">
                                  {sailor_info.Passport_Valid_Through}
                                  </Moment> : ""}</StyledTableCell>

                                </StyledTableRow>
                                
                              </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        
                        <TableContainer component={Paper}>
                            <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Bank Details</StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>

                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Account Holder Name
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Account_Name : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Account No
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Account_Number : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    IFSC Code
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.IFSC_Code : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    SWIFT Code
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.SWIFT_Code : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Branch Address
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info ? sailor_info.Branch_Code : ""}</StyledTableCell>

                                </StyledTableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        <TableContainer component={Paper}>
                          <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Experience Details</StyledTableCell>
                                <StyledTableCell align="right"></StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Last Post Held
                            </StyledTableCell>
                            <StyledTableCell align="right">{sailor_info ? sailor_info.Last_Position_held : ""}</StyledTableCell>

                          </StyledTableRow>
                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                              Year of Experience at last held
                            </StyledTableCell>
                            <StyledTableCell align="right">{sailor_info ? sailor_info.Years_Of_Experience : ""}</StyledTableCell>

                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell component="th" scope="row">
                            Position Applying For
                            </StyledTableCell>
                            <StyledTableCell align="right">{sailor_info.Position_Applying_for ? sailor_info.Position_Applying_for : ""}</StyledTableCell>

                          </StyledTableRow>

                          </TableBody>
                          </Table>
                        </TableContainer>
                         <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Documents</StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                </TableRow>

                              </TableHead>
                              <TableBody>
                                
                                 {JSON.parse(JSON.stringify(sailor_info.Certificates)).map((resp, index) => (
                                

                                  <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row">
                                      {resp.Course_Name}
                                    </StyledTableCell>
                                    <StyledTableCell align="right"> {resp.Issusing_Authority}</StyledTableCell>
                                    <StyledTableCell align="right"> {resp.Certificate_Valid_Till}</StyledTableCell>
                                    <StyledTableCell align="right"></StyledTableCell>

                                  </StyledTableRow>
                                  
                                 ))}  
                                 
                              </TableBody>
                            </Table>
                            <div style={{align:"center",cursor: "pointer",alignItems: "center", display: "flex", justifyContent: "center"}}><a onClick={()=>{downloadDocument(sailor_info.onBoardingID)}} >Download Documents</a></div>
                        <TableContainer component={Paper}>
                          <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell colSpan={3}>Passport Verification</StyledTableCell>

                              </TableRow>

                            </TableHead>
                            <TableBody>
                              <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                  <img src={img} style={{ width: '100px' }} />
                                </StyledTableCell>
                                <StyledTableCell align="center"><img src={bank_verified} style={{ width: '100px' }} /></StyledTableCell>
                                {/* <StyledTableCell align="right"> <img src={img} style={{ width: '100px' }} /></StyledTableCell> */}

                              </StyledTableRow>

                              <StyledTableRow>

                                {/* <StyledTableCell align="center" colSpan={3}><Alert severity="success" className="alert-message" sx={{ fontSize: "18px", width: "100%", color: "#2e7d32", border: "red" }}>Face Match : 98%  Passport Text Match: 100%  Document OCR: 98% </Alert></StyledTableCell> */}

                                <StyledTableCell align="center" colSpan={3}><Alert severity="success" className="alert-message" sx={{ fontSize: "18px", width: "100%", color: "#2e7d32", border: "red" }}>Face Match : {sailor_info.face_match_per}%   <br/> <br/> Passport Match: {sailor_info.passport_match_per}%  </Alert></StyledTableCell>



                              </StyledTableRow>

                            </TableBody>
                          </Table>
                        </TableContainer>




                      </Grid>
                      {data.length > 0 ? (
                      <Grid item sm={12} xs={12}>
                        <TableContainer>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ background: '#ddd' }}>S.No</TableCell>
                                <TableCell align="center" style={{ background: '#ddd' }}>Onboarding ID</TableCell>
                                <TableCell align="center" style={{ background: '#ddd' }}>Sailor From</TableCell>
                                <TableCell align="center" style={{ background: '#ddd' }}>Sailor To</TableCell>
                                <TableCell align="center" style={{ background: '#ddd' }}>Ship Type</TableCell>
                                <TableCell align="center" style={{ background: '#ddd' }}>IMo No</TableCell>
                                <TableCell align="center" style={{ background: '#ddd' }}>End Date</TableCell>
                                <TableCell align="center" style={{ background: '#ddd' }}>Status</TableCell>




                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {data.map((row) => (
                                    <TableRow
                                        key={row.serialNumber}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" style={{ padding: '5px 15px' }}>
                                            {row.serialNumber}
                                        </TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.onBoardingID}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.sailorFrom}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.sailorTo}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.shipType}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.IMoNumber}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{<Moment format="DD/MM/YYYY">
                                  {row.endDate}
                                  </Moment>}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.status}</TableCell>
                                    </TableRow>
                                ))}
                            
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      ) : ""}
                      { ScreenView === 2 && (<>
                      <Grid item sm={5} xs={12}>
                        <TextField fullWidth
                          id="outlined-textarea"
                          label="Add comments"
                          name="comments"
                          multiline
                          rows={4}
                           onChange={handleChange}
                          placeholder="Placeholder"

                        />
                      </Grid>
                      <Grid item sm={5} xs={12}>
                        
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Status</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="status"
                            // value={sailor_info ? sailor_info.Application_Status : ""}
                            label="Status"
                            onChange={handleChange}
                          >
                            <MenuItem value={"Approved"}>Approve</MenuItem>
                            <MenuItem value={"Pending"}>Pending</MenuItem>
                            <MenuItem value={"Hold"}>Hold</MenuItem>
                            <MenuItem value={"Reject"}>Reject </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item sm={2} xs={12}><Button variant="contained" fullWidth color="success" onClick={() => onSubmitInfo() } className="float-end">
                        Submit
                      </Button></Grid></>)}
                      {/* {JSON.stringify(applicationStatus)} */}
                      
                    </Grid>
                     

                  </Box>

                </div>

                <ToastContainer />
              </div>
            </div>
          </section>
        </div>
      </div>





    </>
  );
}

export default Viewonboard;