import React, { useState, useEffect, useRef } from "react";
import Footer from "../../common/footer";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import PhoneInput from 'react-phone-input-2'
import passport_img from "../../assets/images/sailor/rajat.png"
import bank_verified from "../../assets/images/sailor/verified.png"
import 'react-phone-input-2/lib/material.css'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormHelperText } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Banner from "../../components/banner";
import Webcam from "react-webcam";
import axios from 'axios';
import { Grid, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { config } from "../../util/apiconfig";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ELogo from "../../assets/images/sailor/logo.svg";
import ELogo1 from "../../assets/images/sailor/sailoronboard.svg";
import swal from 'sweetalert';
import Moment from 'react-moment';
import DeleteIcon from '@mui/icons-material/Delete';


const countrycitystateHandler = require('countrycitystatejson');

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,

  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,

  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '17px' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },

}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const steps = ['Personal Information', 'Review and Submit'];


function Registration() {
  let CryptoJS = require("crypto-js");
  var FormData = require('form-data');

  // =================== Face Match State Declaration ================
  const [Passport, setPassport] = useState('')
  const [cheque, setCheque] = useState('')
  const [insurance, setInsurance] = useState('')
  const [certificate_doc, setCertificate_doc] = useState('')
  const [aadharFrontImage, setaadharFrontImage] = useState('')
  const [sailor_info, setsailor_info] = useState([])
  const [sailor_formdata_info, setsailor_formdata_info] = useState([])
  const [certificate_list, setCertificatelist] = useState([])
  const [state_list, setStatelist] = useState([])
  const [country_list, setCountrylist] = useState([])
  useEffect(() => {
    certificates();
    getStateList();
    getCountryList();
  }, []);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 0,
    },
    preview: {
      marginTop: 0,
      display: "flex",
      flexDirection: "column",
    },
    image: { maxWidth: "100%", maxHeight: 320 },
    delete: {
      cursor: "pointer",
      padding: 15,
      background: "red",
      color: "white",
      border: "none",
    },
  };
// Start certificate list

const certificates = () => {

  axios.get(config.mongo_endpoint + 'api/v1/certificate/all')
    .then(response => {
     
      setCertificatelist(response.data.data);
      console.log(response.data);
    })
    .catch(error => {
      console.log(error);
      alert(error);
    });

}

// End Certificate List

// State List Starts
const getStateList = () => {
  let data = countrycitystateHandler.getStatesByShort('IN');
  console.log(data);
  let obj={};
  let stateListData=[];
  data.map((item,index) => {
    obj={
      value: `${item}`,
      label: `${item}`
    };
    stateListData.push(obj);
  })
  setStatelist(stateListData);
}


// State List End

// Country List Starts
const getCountryList = () => {
  let data = countrycitystateHandler.getCountries();
  console.log(data);
  let obj={};
  let countryListData=[];
  data.forEach((country, index) => {
    obj={
      value: `${country.name}`,
      label: `${country.name}`
    };
    countryListData.push(obj);
  });
  setCountrylist(countryListData);
}


// Country List End

  // =================== Voter Info Validation Logic - Using Yup / React Hooks Form ================

  // =================== Voter Info Tab-1 Validation  ================

  const sailorInformation = yup.object().shape({
    email_Id: yup
    .string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      'Invalid email'
    )
    .required('Email is required'),
    first_name: yup.string().required("First Name Required").matches(/^[A-Za-z\s]*$/,"Only Alphabets allowed"),
    last_name: yup.string().required("Last Name Required").matches(/^[A-Za-z\s]*$/,"Only Alphabets allowed"),
    date_of_birth: yup.string().required("Date of birth Required"),
    place_of_birth: yup.string().required("Place of birth Required").matches(/^[A-Za-z\s]*$/,"Only Alphabets allowed"),
    blood_group: yup.string().required("Blood Group Required"),
    marital_status: yup.string().required("Marital Status Required"),
    country: yup.string().required("Country Required"),
    nationality: yup.string().required("Nationality Required"),
    mobile_number: yup
          .string()
          .required("Phone Number is required")
          .matches(
            /^[0-9]{10,15}$/,
            'Phone number must be between 10 to 15 digits'
          ), // Use regex pattern to validate phone number format,
    emergency_person: yup.string().required("Emergency Contact Person Required"),
    emergency_number: yup.string().required("Emergency Contact Number Required").matches(
      /^[0-9]{10}$/,
      'Phone number must be 10 digits'
    ),
    address: yup.string().required("Address Required"),
    permanent_address: yup.string().required("Permanent Address Required"),     
      password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters'),
    confirm_password: yup
      .string()
      .required('Confirm Password is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    holder_name: yup.string().required("Account Holder Name Required"),
    account_number: yup.string().required("Account Number Required"),
    ifsc_code: yup.string().required("IFSC Code Required"),
    branch_address: yup.string().required("Branch Address Required"),    
    passport_no: yup
    .string()
    .length(8, 'Passport number must be exactly 8 characters')
    .required('Passport number is required'),
    place_of_issued: yup.string().required("Place of Issued Required"),
    date_of_issued: yup.string().required("Date of Issued Required"),
    valid_till: yup.string().required("Valid Till Required"),   
    insurance_no: yup
    .string()
    .length(10, 'Insurance number must be exactly 10 characters')
    .required('Insurance number is required'),
    post_held: yup.string().required("Last Post Held Required"),
    last_held: yup.string().required("Year of Experience of Last Held Required"),
    rows: yup.array().of(
      yup.object().shape({
        certificate: yup.string().required("Select the Certificate"),
        certificate_number:yup
        .string()
        .required('Certificate number is required')
        .length(12, 'Certificate number must be exactly 12 characters'),
        issue_authority: yup.string().required("Issuing Authority Required"),
        valid_date: yup.string().required("Select Valid Date"),
      })
    ),

    passport_document: yup.mixed().required("File required")
    .test('fileSize', 'Please upload the Passport Image', (value) => {

      if (value.length === 0){
        setPassport({'message':"Please upload the file",'error':true})
        return false
      } else {
        setPassport({'message':"",'error':false})
        return true
      }
    }),

    penny_check_verified: yup.mixed().required("File required")
    .test('fileSize', 'Please upload the Cancelled Cheque', (value) => {

      if (value.length === 0){
        setCheque({'message':"Please upload the file",'error':true})
        return false
      } else {
        setCheque({'message':"",'error':false})
        return true
      }
    }),

    insurance_document: yup.mixed().required("File required")
    .test('fileSize', 'Please upload the Insurance Document', (value) => {

      if (value.length === 0){
        setInsurance({'message':"Please upload the file",'error':true})
        return false
      } else {
        setInsurance({'message':"",'error':false})
        return true
      }
    }),

    // course_document: yup.mixed().required('A file is required')
    // .test('fileSize', 'Please upload the Certificate', (value) => {

    //   if (value.length === 0){
    //     setCertificate_doc({'message':"Please upload the file",'error':true})
    //     return false
    //   } else {
    //     setCertificate_doc({'message':"",'error':false})
    //     return true
    //   }
    // }),

  });

  // =================== Voter Info Tab-1 Validation  ================
  // =================== Voter Info Yup Resolver Starts  ================
  const { register: sailorInfoHandler,
    handleSubmit: sailorInfoHandlerSubmit,
    reset: voterInfoReset,
    control,
    watch,
    setValue,
    formState: { errors: sailorInfoErrors } } = useForm({
      resolver: yupResolver(sailorInformation),
      mode: 'OnSubmit',
      defaultValues: {
        rows: [{ certificate: "",certificate_number: "", issue_authority: "", course_document: "", valid_date: "" }],
      },
    });

  console.log(sailorInfoErrors)

 
  const [Agree, setAgree] = useState('')
  const [links, setLinks] = useState(null)
  const Agreeterms = (event) => {

    if (event.target.checked === true) {
      setAgree(true)
      setLinks("/evoting")
    } else {
      setAgree(false)
      setLinks(null)
    }
  }

  const AgreeNow = () => {
    if (Agree === true) {
      console.log("sailor",sailor_info);
      onSubmitRegistration(sailor_formdata_info);
      // LandingScreen();
    } else {
      setAgree(false)
    }
  }
  // =================== Voter Info Yup Resolver Ends  ================

  const [open, setOpen] = React.useState(false);
  const [age, setAge] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };
  const handleDateChange = (formdate) => {
    if (formdate) {
      const date = new Date(formdate);
      // Format the date to YYYY-MM-DD
      const formattedDate = date.toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
      }).split('/').reverse().join('-');
      return formattedDate;
    }
   
  };

  const handlePassportDate = (formdate) => {
    if (formdate) {
      const date = new Date(formdate);
      // Format the date to YYYY-MM-DD
      const formattedDate = date.toLocaleDateString('en-GB', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
      }).split('/').reverse().join('-');
      return formattedDate;
    }
   
  };

  const passport_info_verify = (passport,valid_till,passport_no) => {
     
  }

  const passport_image_verify =  () => {
    const base64Data = picture;
    const [imageType, imageData] = base64Data.split(";base64,");
    const blob = base64ToBlob(imageData, imageType);
    const temporaryFile = new File([blob], "image.jpg", { type: imageType });
    
    let facematch_info = new FormData();
    facematch_info.append("matchFile",passportImage[0]);
    if(picture === ''){
      facematch_info.append("file",selectedImage);
    } else {
      facematch_info.append("file",temporaryFile);
    }
    console.log("facematch",facematch_info);
    axios.post(config.face_match_api, facematch_info, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(result => {
        if (result.data.Status == "Success") {
          const match_result_per = result.data.Response.match_result_per;
          const match_result_percentage = match_result_per.replace(/\[|\]/g, '');

          if (result.data.Response.match_result === "[True]" || match_result_percentage >= "40") {
            const match_result = result.data.Response.match_result;
            const match_result_final = match_result.replace(/\[|\]/g, '');

            return {"status":true, "match_per":match_result_percentage,"match_result":result.data.Response.match_result }

          } else {
            return {"status":false, "match_per":match_result_percentage,"match_result":result.data.Response.match_result }
          }

        }


      })
      .catch(error => {
        // handle the error
        console.error(error);
        if (error) {
          alert("Something went wrong. Please try again Later....")

        }
       //  setface_match_loader(false)
      });
 }

  const [passportMatch, setPassportMatch] = React.useState(false);
  const [faceMatch, setfaceMatch] = React.useState(false);
  const onSubmit = async (data) => {

    try {
      console.log(data);
      let passport = new FormData();
      passport.append('file', passportImage[0]);

      const passportInfo = await axios.post(config.passport_api, passport, { headers: { 'Content-Type': 'multipart/form-data'} });
     if(passportInfo.data.Response){
      let passport_number = passportInfo.data.Response.number.replace('<', "");
      console.log("passport", passport_number);
      let expiration_date = passportInfo.data.Response.expiration_date.slice(0, 2)+'-'+passportInfo.data.Response.expiration_date.slice(2, 4)+'-'+passportInfo.data.Response.expiration_date.slice(4, 6);
      if( passport_number === data["passport_no"] && handlePassportDate(data["valid_till"]) === expiration_date){
        console.log("passport1", passportInfo);
        
            let facematch_info = new FormData();
            facematch_info.append("matchFile",passportImage[0]);
            facematch_info.append("file",data["profile_image"]);
           

          const passport_image_verify_info = await axios.post(config.face_match_api, facematch_info, { headers: { 'Content-Type': 'multipart/form-data' } });
            console.log("passport2",facematch_info)
            console.log("passport3",passport_image_verify_info)
              if (passport_image_verify_info.data.Status == "Success") {
                const match_result_per = passport_image_verify_info.data.Response.match_result_per;
                const match_result_percentage = match_result_per.replace(/\[|\]/g, '');

                if (passport_image_verify_info.data.Response.match_result === "[True]" || match_result_percentage >= "30") {
                  const match_result = passport_image_verify_info.data.Response.match_result;
                  const match_result_final = match_result.replace(/\[|\]/g, '');
                  
                  let sailor_request = new FormData();
                  sailor_request.append("Email" , data["email_Id"]);
                  sailor_request.append("Password" , data["password"]);
                  sailor_request.append("First_Name" , data["first_name"]);
                  sailor_request.append("Middle_Name" , data["middle_name"]);
                  sailor_request.append("Last_Name" , data["last_name"]);
                  sailor_request.append("Date_of_Birth" , handleDateChange(data["date_of_birth"]));
                  sailor_request.append("Place_of_Birth" , data["place_of_birth"]);
                  sailor_request.append("Blood_Group" ,data["blood_group"]);
                  sailor_request.append("Maritial_Status" ,data["marital_status"]);
                  sailor_request.append("Country" ,data["country"]);
                  sailor_request.append("Nationality" ,data["nationality"]);
                  sailor_request.append("Phone" ,data["mobile_number"]);
                  sailor_request.append("Emergency_Contact_Person" ,data["emergency_person"]);    
                  sailor_request.append("Emergency_Contact_Number" ,data["emergency_number"]);        
                  sailor_request.append("Corresponding_Address" ,data["address"]);
                  sailor_request.append("Permanent_Address" ,data["permanent_address"]);
                  sailor_request.append("Passport_Number" ,data["passport_no"]);
                  sailor_request.append("Place_Of_Issue" ,data["place_of_issued"]);
                  sailor_request.append("Date_Of_Issue" , handleDateChange(data["date_of_issued"]));
                  sailor_request.append("Passport_Valid_Through" , handleDateChange(data["valid_till"]));
                  sailor_request.append("Account_Name" ,data["holder_name"]);
                  sailor_request.append("Account_Number" ,data["account_number"]);
                  sailor_request.append("IFSC_Code" ,data["ifsc_code"]);
                  sailor_request.append("SWIFT_Code" ,data["swift_code"]);
                  sailor_request.append("Branch_Code" ,data["branch_address"]);
                  sailor_request.append("Insaurance_Number" ,data["insurance_no"]);
                  sailor_request.append("Last_Position_held" ,data["post_held"]);
                  sailor_request.append("Years_Of_Experience" ,data["last_held"]);
                  sailor_request.append("Position_Applying_for" ,data["position_apply_for"]);
                 
                  sailor_request.append("files" , data["profile_image"]);
                  sailor_request.append("files" , passportImage[0]);
                  sailor_request.append("files" , data["penny_check_verified"][0]);
                  sailor_request.append("files" , data["insurance_document"][0]);
                  sailor_request.append("passport_match" , true);
                  sailor_request.append("face_match" , true);
                  sailor_request.append("passport_match_per" , String(parseInt(match_result_percentage)));
                  sailor_request.append("face_match_per" , String(passportInfo.data.Response.valid_score));

                  const certificate_array = [];
                      for(var i = 0; i < data.rows.length; i++){
                                  let certificate = 
                          {
                            "Course_Name": data.rows[i].certificate,
                            "Certificate_Number": data.rows[i].certificate_number,
                            "Issusing_Authority": data.rows[i].issue_authority,
                            "Certificate_Valid_Till": handleDateChange(data.rows[i].valid_date),
                            "Certificate_Document_Path": data.rows[i].course_document.name,
                            
                        }
                        certificate_array.push(certificate);
                        sailor_request.append("files" , data.rows[i].course_document);
                      }

                  sailor_request.append("Certificates" , JSON.stringify(certificate_array));

                  const sailorInformation = {
                    "Email" : data["email_Id"],
                    "Password" : data["password"],
                    "First_Name" : data["first_name"],
                    "Middle_Name" : data["middle_name"],
                    "Last_Name" : data["last_name"],
                    "Date_of_Birth" : handleDateChange(data["date_of_birth"]),
                    "Place_of_Birth" : data["place_of_birth"],
                    "Blood_Group" : data["blood_group"],
                    "Maritial_Status" :data["marital_status"],
                    "Country" :data["country"],
                    "Nationality" :data["nationality"],
                    "Phone" :data["mobile_number"],
                    "Emergency_Contact_Person" :data["emergency_person"],    
                    "Emergency_Contact_Number" :data["emergency_number"],        
                    "Corresponding_Address" :data["address"],
                    "Permanent_Address" :data["permanent_address"],
                    "Photo" : data["profile_image"],
                    "Passport_Number" :data["passport_no"],
                    "Place_Of_Issue" :data["place_of_issued"],
                    "Date_Of_Issue" : handleDateChange(data["date_of_issued"]),
                    "Passport_Valid_Through" : handleDateChange(data["valid_till"]),
                    "Passport_image" : passportImage[0],
                    "Account_Name" :data["holder_name"],
                    "Account_Number" :data["account_number"],
                    "IFSC_Code" :data["ifsc_code"],
                    "SWIFT_Code" :data["swift_code"],
                    "Branch_Code" :data["branch_address"],
                    "Canceled_Check": data["penny_check_verified"][0],
                    "Insaurance_Number" :data["insurance_no"],
                    "Insaurance_Document":data["insurance_document"][0],
                    "Last_Position_held" :data["post_held"],
                    "Years_Of_Experience" :data["last_held"],
                    "Position_Applying_for" :data["position_apply_for"], 
                    "Certificates" : certificate_array,
                    "files" : [data["profile_image"],passportImage[0],data["penny_check_verified"][0],data["insurance_document"][0]],
                    "passport_match" : true,
                    "face_match":true,
                    "face_match_per":parseInt(match_result_percentage),
                    "passport_match_per":passportInfo.data.Response.valid_score
                  }
                  setsailor_info(sailorInformation);
                  console.log("sailor",sailorInformation);

                 
                  const created_date = new Date()
                  sailor_request.append("Created_Date" , created_date);
                  

                  setsailor_formdata_info(sailor_request);
                  handleNext()
                  console.log("sailor_info",sailor_info);

                } else {
                  toast.error("Face Match Failed......!", {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                  });
                }

              }
      } else{
        toast.error("Passport Match Failed.......!", {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
        });
      }
     }

        
    } catch (error) {
      console.error(error);
      // if (error)
      setLoader(false);
      if (error) {
        alert("Something went wrong. Please try again Later....")
      }
      console.log(error.code);
      // setError(error.message);

      // }
    }
    //------------------- Voter Information check ---------------------------
  }

  const onSubmitRegistration = async (sailor_info) => {
  
    try {
    
        await axios.post(config.mongo_endpoint+'api/v1/sailorUser/signUp', sailor_info, { headers: { 'Content-Type': 'multipart/form-data'} })
                    .then(result => {
                      console.log(result);
                      if(result.status == 200 && result.data.status == "Success"){
                       
                        swal("Success", "Registration Completed", "success");
                        

                        setTimeout(function() {LandingScreen();}, 3000);

                      } else if(result.status == 200 && result.data.status == "Error"){
                  
                        swal("Oops!", result.data.message, "error");                        
                        
                      } else {
                        swal("Oops!", "Something went wrong try again later.", "error");
                        
                      }
                      
                      
                    })
                    .catch(error => {
                      // handle the error
                      console.error(error);
                      if (error) {
                        
                        toast.error("Something went When wrong. Please try again Later....", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                      });
              
                      }
                    
                    });

  
    } catch (error) {
      console.error(error);
      // if (error)
      setLoader(false);
      if (error) {
        alert("Something went wrong. Please try again Later....")
      }
      console.log(error.code);
      // setError(error.message);

      // }
    }
    //------------------- Voter Information check ---------------------------
  }
  // =================== Voter Info Submit Handler Ends  ================



  // console.log("C",constitution)
  // =================== Constitution API ======================================
  const [rows, setRows] = useState(1);

  const handleAddRow = () => {
    setRows(rows + 1);
  }
  const [loader, setLoader] = useState(false)
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  //video code 
  const videoConstraints = {
    width: 400,
    height: 400,
    facingMode: "user"
  };
  const [picture, setPicture] = useState('')
  const [faceMatchStatus, setfaceMatchStatus] = useState(false)
  const [faceLivenessStatus, setLivenessStatus] = useState(false);
  const webcamRef = React.useRef(null)

  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot()
    setPicture(pictureSrc);
    const base64Data = pictureSrc;
    const [imageType, imageData] = base64Data.split(";base64,");
    const blob = base64ToBlob(imageData, imageType);
    const temporaryFile = new File([blob], "image.jpg", { type: imageType });
    setValue('profile_image',temporaryFile);
    dateTimeCalc();
    // if (activeStep === 2) {
    //   handleFaceMatching(pictureSrc);
    // }
  })
  //video code ends

  // ====================== Voting Selection Data ==========================

  const dateTimeCalc = () => {
    const date = new Date(); // Assuming the input date is in UTC
    const options = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZoneName: 'short',
    };
    const formattedDate = date.toLocaleString('en-IN', options); // Using 'en-IN' for timezone 'GMT +05:30' 
    // setcaptureDateTime(formattedDate);
  }
  const [VoterInfo, setVoterInfo] = useState({});


  // ====================== Voting Selection Data ==========================

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    console.log(activeStep);
    setSkipped(newSkipped);
    if (activeStep === 1) {
      voterInfoReset();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const base64ToBlob = (base64Data, contentType) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  // const [face_match_loader, setface_match_loader] = useState(false);
  // const processFaceMatch = async () => {
  //   console.log("checking processFaceMatch");
  //   setface_match_loader(true)
  //   const base64Data = picture;
  //   const [imageType, imageData] = base64Data.split(";base64,");
  //   const blob = base64ToBlob(imageData, imageType);
  //   const temporaryFile = new File([blob], "image.jpg", { type: imageType });
  //   console.log("facematch",temporaryFile);
  //   console.log("facematch",aadharFrontImage);
  //   // Now you can use `temporaryFile` for whatever you need.
  //   // When you're done with it, you should delete the temporary file:
  //   // URL.revokeObjectURL(temporaryFile);
  //   let facematch_info = new FormData();
  //   facematch_info.append("matchFile",passportImage[0]);
  //   if(picture === ''){
  //     facematch_info.append("file",selectedImage);
  //   } else {
  //     facematch_info.append("file",temporaryFile);
  //   }
    
  //   // const MatchData = { "matchFile": aadharFrontImage, "file": temporaryFile };
  //   console.log("facematch",facematch_info)
  //   // alert("faceImage:"+temporaryFile);
  //   await axios.post(config.face_match_api, facematch_info, { headers: { 'Content-Type': 'multipart/form-data' } })
  //     .then(result => {
  //       if (result.data.Status == "Success") {
  //         setface_match_loader(false)
  //         const match_result_per = result.data.Response.match_result_per;
  //         const match_result_percentage = match_result_per.replace(/\[|\]/g, '');

  //         if (result.data.Response.match_result === "[True]" || match_result_percentage >= "40") {
  //           const match_result = result.data.Response.match_result;
  //           const match_result_final = match_result.replace(/\[|\]/g, '');

  //           setfaceMatchStatus(Boolean(match_result_final));
  //           // setfaceMatchPercentage(match_result_percentage);
  //           // console.log(Boolean(match_result_final));
  //           // console.log(result.data.Response.match_result_per);

  //         } else {
  //           setface_match_loader(false)
  //           setfaceMatchStatus(false);
  //         }

  //       }


  //     })
  //     .catch(error => {
  //       // handle the error
  //       console.error(error);
  //       if (error) {
  //         alert("Something went wrong. Please try again Later....")

  //       }
  //       setface_match_loader(false)
  //     });
  // };

  const [selectedImage, setSelectedImage] = useState();

  // This function will be triggered when the file field change
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log(e.target.files[0]);
      setSelectedImage(e.target.files[0]);
      setValue('profile_image', e.target.files[0]);
      setPicture("");
      
    }
  };
  
  const [passportImage, setPassportImage] = useState([])
  useEffect(() => {
    // This code will run whenever `passportImage` is updated
    console.log('passportImage:', passportImage);
  }, [passportImage]);

  const HandleChangePassport = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileDatas = e.target.files[0];
      setPassportImage([fileDatas]);
      setValue('passport_document',e.target.files[0]);
      console.log("facematch",fileDatas);
    console.log("facematch",passportImage);
    }
    
  };

  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = () => {
    setSelectedImage();
  };


  const [faceVerifyError, setfaceVerifyError] = useState('');
  const faceVerificationCheck = () => {
    if (faceMatchStatus === false && faceLivenessStatus === false) {

      // setVoterInfoDraftStatus(true)
      // }
      // alert(Array.from(selectedValue).length+""+)
      // setfaceVerifyError(false);
      // voting_candidates();

      handleNext();
    } else {
      // setfaceVerifyError(false);
      toast.error("Face Verification Failed..!", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
      });
    }
  }
  const navigate = useNavigate();
  const LandingScreen = () => {
    navigate("/");
  }
  const { fields, append, remove } = useFieldArray({
    control,
    name: "rows",
  });

  return (
    <>


<div class="reg-section bg" style={{backgroundImage: `url(${require("../../assets/images/sailor/login-bg.svg").default})`}}>
			<div class="container">
				<div class="reg-white">
      
        <section className="voting-section">
        <div className="container p-4">
          <div className="row">
          <Link to="/"><img src={ELogo} className="img-fluid light-logo mb-3" alt="" width="70" />
              <img src={ELogo1} className="img-fluid logo-txt ml-10" alt="" width="100" height="93" />
            </Link>
            <br/>

            {/* <h1>Voting</h1> */}
            <Box sx={{ width: '100%' }}>
              <Stepper sx={{ fontSize: 25}} activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel  {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              {/* ====== step 0 starts  ====== */}
              {activeStep === 0 ? (
                <>
                  <form onSubmit={sailorInfoHandlerSubmit(onSubmit)}>
                    <div className="w-100">

                      <Typography variant="h6" mb={3} sx={{ color: "#333", textAlign: "left", }}>
                        User Account Details
                      </Typography>

                      <Box mb={3.5}>
                        <Grid container spacing={3}>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              size="small"
                              fullWidth
                              type="text"
                              name="email_Id"
                              label="Email ID *"
                              {...sailorInfoHandler("email_Id")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['email_Id']}
                              helperText={sailorInfoErrors['email_Id'] ? sailorInfoErrors['email_Id'].message : ''}
                            />

                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              size="small"
                              fullWidth
                              type="password"
                              name="password"
                              label="Password *"
                              {...sailorInfoHandler("password")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['password']}
                              helperText={sailorInfoErrors['password'] ? sailorInfoErrors['password'].message : ''}
                            />

                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              size="small"
                              fullWidth
                              type="password"
                              name="confirm_password"
                              label="Confirm Password *"
                              {...sailorInfoHandler("confirm_password")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['confirm_password']}
                              helperText={sailorInfoErrors['confirm_password'] ? sailorInfoErrors['confirm_password'].message : ''}
                            />

                          </Grid>
                        </Grid>
                      </Box>
                      <Typography variant="h6" mb={3} sx={{ color: "#333", textAlign: "left", }}>
                        Personal Information
                      </Typography>
                      <Box mb={3.5}>
                        <Grid container spacing={3}>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              size="small"
                              fullWidth
                              type="text"
                              name="first_name"
                              label="First Name *"
                              {...sailorInfoHandler("first_name")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['first_name']}
                              helperText={sailorInfoErrors['first_name'] ? sailorInfoErrors['first_name'].message : ''}
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              fullWidth
                              size="small"
                              type="text"
                              name="middle_name"
                              label="Middle Name"
                              {...sailorInfoHandler("middle_name")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['middle_name']}
                              helperText={sailorInfoErrors['middle_name'] ? sailorInfoErrors['middle_name'].message : ''}
                              
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              fullWidth
                              type="text"
                              size="small"
                              name="last_name"
                              label="Last Name *"
                              {...sailorInfoHandler("last_name")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['last_name']}
                              helperText={sailorInfoErrors['last_name'] ? sailorInfoErrors['last_name'].message : ''}
                              
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                <DatePicker
                                  label="Date of Birth *"
                                  name="date_of_birth"
                                  {...sailorInfoHandler("date_of_birth")}
                                  slotProps={{ textField: { size: 'small' } }}
                                  onChange={(date_of_birth) => {
                                    setValue('date_of_birth', date_of_birth); 
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>

                            {sailorInfoErrors.date_of_birth && <FormHelperText error={!!sailorInfoErrors['date_of_birth']}>{sailorInfoErrors.date_of_birth.message}</FormHelperText>}
                          </Grid>
                          <Grid item sm={4} xs={12}>
                          <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Place of Birth *</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                name="place_of_birth"
                                label="Place of Birth *"
                                {...sailorInfoHandler("place_of_birth")}
                                error={!!sailorInfoErrors['place_of_birth']}
                                helperText={sailorInfoErrors['place_of_birth'] ? sailorInfoErrors['place_of_birth'].message : ''}
                              >
                                <MenuItem value="0">Select</MenuItem>
                                {
                                  state_list.map((list) => (
                                    <MenuItem value={list.value}>{list.label}</MenuItem>
                                  ))
                                }

                              </Select>
                              {sailorInfoErrors.place_of_birth && <FormHelperText error={!!sailorInfoErrors['place_of_birth']}>{sailorInfoErrors.place_of_birth.message}</FormHelperText>}
                            </FormControl>
                           
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Blood Group *</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                name="blood_group"
                                label="Blood Group *"
                                {...sailorInfoHandler("blood_group")}
                                error={!!sailorInfoErrors['blood_group']}
                                helperText={sailorInfoErrors['blood_group'] ? sailorInfoErrors['blood_group'].message : ''}
                              >
                                <MenuItem value="1">A+</MenuItem>
                                <MenuItem value="2">O+</MenuItem>
                                <MenuItem value="3">B+</MenuItem>
                                <MenuItem value="4">AB+</MenuItem>
                                <MenuItem value="5">A-</MenuItem>
                                <MenuItem value="5">O-</MenuItem>
                                <MenuItem value="6">B-</MenuItem>
                                <MenuItem value="7">AB-</MenuItem>
                              </Select>
                              {sailorInfoErrors.blood_group && <FormHelperText error={!!sailorInfoErrors['blood_group']}>{sailorInfoErrors.blood_group.message}</FormHelperText>}
                            </FormControl>

                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Marital Status*</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                name="marital_status"
                                label="Marital Status *"
                                {...sailorInfoHandler("marital_status")}
                                error={!!sailorInfoErrors['marital_status']}
                                helperText={sailorInfoErrors['marital_status'] ? sailorInfoErrors['marital_status'].message : ''}
                              >
                                <MenuItem value="Single">Single</MenuItem>
                                <MenuItem value="Married">Married</MenuItem>
                                <MenuItem value="Widowed">Widowed </MenuItem>
                                <MenuItem value="Divorced">Divorced </MenuItem>
                              </Select>
                              {sailorInfoErrors.marital_status && <FormHelperText error={!!sailorInfoErrors['marital_status']}>{sailorInfoErrors.marital_status.message}</FormHelperText>}
                            </FormControl>

                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Country *</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                name="country"
                                label="Country *"
                                {...sailorInfoHandler("country")}
                                error={!!sailorInfoErrors['country']}
                                helperText={sailorInfoErrors['country'] ? sailorInfoErrors['country'].message : ''}
                              >
                                <MenuItem value="Country">Country</MenuItem>
                                {
                                  country_list.map((list) => (
                                    <MenuItem value={list.value}>{list.label}</MenuItem>
                                  ))
                                }

                              </Select>
                              {sailorInfoErrors.country && <FormHelperText error={!!sailorInfoErrors['country']}>{sailorInfoErrors.country.message}</FormHelperText>}
                            </FormControl>
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              fullWidth
                              type="text"
                              size="small"
                              name="nationality"
                              label="Nationality *"
                              {...sailorInfoHandler("nationality")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['nationality']}
                              helperText={sailorInfoErrors['nationality'] ? sailorInfoErrors['nationality'].message : ''}
                              
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>


                            <Controller
                              control={control}
                              name="mobile_number"
                              render={({ field }) => (
                                <PhoneInput
                                  country={'us'}
                                  {...field}
                                  inputProps={{
                                    name: "mobile_number",
                                    required: true,
                                  }}
                                />
                              )}
                            />
                            {sailorInfoErrors.mobile_number && <FormHelperText error={sailorInfoErrors.mobile_number}>{sailorInfoErrors.mobile_number.message}</FormHelperText>}

                         </Grid>

                          <Grid item sm={4} xs={12}>
                            <TextField
                              fullWidth
                              size="small"
                              type="text"
                              name="emergency_person"
                              label="Emergency Contact Person *"
                              {...sailorInfoHandler("emergency_person")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['emergency_person']}
                              helperText={sailorInfoErrors['emergency_person'] ? sailorInfoErrors['emergency_person'].message : ''}
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              fullWidth
                              size="small"
                              type="text"
                              name="emergency_number"
                              label="Emergency Contact Number *"
                              {...sailorInfoHandler("emergency_number")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['emergency_number']}
                              helperText={sailorInfoErrors['emergency_number'] ? sailorInfoErrors['emergency_number'].message : ''}
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <TextField
                              fullWidth
                              name="address"
                              label="Corresponding Address *"
                              multiline
                              minRows={3}
                              maxRows={10}
                              {...sailorInfoHandler("address")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['address']}
                              helperText={sailorInfoErrors['address'] ? sailorInfoErrors['address'].message : ''}
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <TextField
                              fullWidth
                              name="permanent_address"
                              label="Permanent Address*"
                              multiline
                              minRows={3}
                              maxRows={10}
                              {...sailorInfoHandler("permanent_address")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['permanent_address']}
                              helperText={sailorInfoErrors['permanent_address'] ? sailorInfoErrors['permanent_address'].message : ''}
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                          <div>
                              <Button  variant="contained"
                                component="label" onClick={handleClickOpen}>Upload Image</Button>
                              <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                                <DialogTitle>Take Capture or Upload Image</DialogTitle>
                                <DialogContent>
                                  <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                  
                                    <div className="video-content">
                                      <div className="video-div">
                                        <div className="webcam-out">
                                          {faceMatchStatus === true ? (
                                            <div className="webcam-image">
                                              {picture === "" ? (
                                                <Webcam
                                                  audio={false}
                                                  height={280}
                                                  ref={webcamRef}
                                                  width={400}
                                                  screenshotFormat="image/jpeg"
                                                  videoConstraints={videoConstraints}
                                                />
                                              ) : (
                                                <img src={picture} />
                                              )}
                                               <div className="video-btn">
                                                  <div className="video-btn-lft">
                                                    <button
                                                      className="btn-black"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        setPicture("");
                                                      }}
                                                    >
                                                      Retake
                                                    </button>
                                                    <button
                                                      className="btn-org"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        capture();
                                                      }}
                                                    >
                                                      Capture
                                                    </button>
                                                  </div>
                                                  
                                                  {/* <button
                                                    className="btn-grn"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      processFaceMatch();
                                                    }}
                                                  >
                                                    Process Match
                                                  </button> */}
                                                  
                                                </div>
                                            </div>
                                            
                                          ) : (<div style={styles.container}>
                                            {selectedImage && (
                                                      <div style={styles.preview}>
                                                        <img
                                                          src={URL.createObjectURL(selectedImage)}
                                                          style={styles.image}
                                                          alt="Thumb"
                                                        />
                                                        <button onClick={removeSelectedImage} style={styles.delete}>
                                                          Remove This Image
                                                        </button>
                                                      </div>
                                                    )}
                                            
                                          </div>)}
                                          
                                        </div>
                                      </div>

                                      
                                    </div>
                                   
                                    <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <Button
                                variant="contained"
                                component="label" onClick={() => {setfaceMatchStatus(true);}} >
                                                          Capture Image
                                                          </Button>
                                   
                              </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Button variant="contained" component="label">
              Upload Image
              <input
                type="file"
                name="profile_image"
                hidden
                {...sailorInfoHandler("profile_image")}
                onChange={(e) => {
                  setfaceMatchStatus(false); // Ensure this is working as expected
                  e.preventDefault(); // This line may not be necessary
                  imageChange(e); // Make sure imageChange is a valid function
                }}                                  
              />
            </Button>
            </FormControl>
          </Box>
                          

                             

                                  </Box>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={handleClose}>Cancel</Button>
                                  <Button onClick={handleClose}>Ok</Button>
                                </DialogActions>
                              </Dialog>
                            </div>
                            {/* <div className="control-div">

                              <Button
                                variant="contained"
                                component="label"
                              >
                                Capture Photo
                                <input
                                  type="file"
                                  name="capture"
                                  hidden
                                  {...sailorInfoHandler("capture")}
                                />
                              </Button>
                            </div> */}
                          </Grid>
                          {/* <Grid item sm={4} xs={12}>
                            <div className="control-div">

                              <Button
                                variant="contained"
                                component="label"
                              >
                                Upload Photo
                                <input
                                  type="file"
                                  name="upload_image"
                                  hidden
                                  {...sailorInfoHandler("upload_image")}
                                />
                              </Button>
                            </div>
                          </Grid> */}

                        </Grid>
                      </Box>
                      <Typography variant="h6" mb={3} sx={{ color: "#333", textAlign: "left", }}>
                        Passport Details
                      </Typography>
                      <Box mb={3.5}>
                        <Grid container spacing={3}>
                          <Grid item sm={3} xs={12}>
                            <TextField
                              fullWidth
                              type="text"
                              name="passport_no"
                              size="small"
                              label="Passport No *"
                              {...sailorInfoHandler("passport_no")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['passport_no']}
                              helperText={sailorInfoErrors['passport_no'] ? sailorInfoErrors['passport_no'].message : ''}
                            />

                          </Grid>
                          <Grid item sm={3} xs={12}>
                            <TextField
                              fullWidth
                              type="text"
                              name="date_of_issued"
                              size="small"
                              label="Place of Issue *"
                              {...sailorInfoHandler("place_of_issued")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['place_of_issued']}
                              helperText={sailorInfoErrors['place_of_issued'] ? sailorInfoErrors['place_of_issued'].message : ''}
                            />

                          </Grid>
                          <Grid item sm={3} xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                <DatePicker
                                  label="Date of Issue *"
                                  {...sailorInfoHandler("date_of_issued")}
                                  slotProps={{ textField: { size: 'small' } }}
                                  onChange={(date_of_issued) => {
                                    setValue('date_of_issued', date_of_issued); // Set the value using Formik's setFieldValue
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            {sailorInfoErrors.date_of_issued && <FormHelperText error={!!sailorInfoErrors['date_of_issued']}>{sailorInfoErrors.date_of_issued.message}</FormHelperText>}
                          </Grid>
                          <Grid item sm={3} xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                <DatePicker
                                  label="Valid through *"
                                  {...sailorInfoHandler("valid_till")}
                                  slotProps={{ textField: { size: 'small' } }}
                                  onChange={(valid_till) => {
                                    setValue('valid_till', valid_till); // Set the value using Formik's setFieldValue
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            {sailorInfoErrors.valid_till && <FormHelperText error={!!sailorInfoErrors['valid_till']}>{sailorInfoErrors.valid_till.message}</FormHelperText>}
                           </Grid>

                          <Grid item sm={3} xs={12}>
                            <div className="control-div">
                              <Button
                                variant="contained"
                                component="label"
                              >
                                Upload Passport
                                <input
                                  type="file"
                                  name="passport_document"
                                  hidden
                                  {...sailorInfoHandler("passport_document")}
                                   onChange={(e) => {
                                   
                                    e.preventDefault(); // This line may not be necessary
                                    HandleChangePassport(e); // Make sure imageChange is a valid function
                                  }}       
                                 
                                  
                                />
                              </Button>
                              {Passport.error && <FormHelperText error={Passport.error}>{Passport.message}</FormHelperText>}
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                      <Typography variant="h6" mb={3} sx={{ color: "#333", textAlign: "left", }}>
                        Bank Account Details 
                      </Typography>

                      <Box mb={3.5}>
                        <Grid container spacing={3}>
                          <Grid item sm={3} xs={12}>
                            <TextField
                              size="small"
                              fullWidth
                              type="text"
                              name="holder_name"
                              label="Account Holder Name *"
                              {...sailorInfoHandler("holder_name")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['holder_name']}
                              helperText={sailorInfoErrors['holder_name'] ? sailorInfoErrors['holder_name'].message : ''}
                            />

                          </Grid>
                          <Grid item sm={3} xs={12}>
                            <TextField
                              size="small"
                              fullWidth
                              type="text"
                              name="account_number"
                              label="Account Number *"
                              {...sailorInfoHandler("account_number")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['account_number']}
                              helperText={sailorInfoErrors['account_number'] ? sailorInfoErrors['account_number'].message : ''}
                            />

                          </Grid>
                          <Grid item sm={3} xs={12}>
                            <TextField
                              size="small"
                              fullWidth
                              type="text"
                              name="ifsc_code"
                              label="IFSC Code *"
                              {...sailorInfoHandler("ifsc_code")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['ifsc_code']}
                              helperText={sailorInfoErrors['ifsc_code'] ? sailorInfoErrors['ifsc_code'].message : ''}
                            />

                          </Grid>
                          <Grid item sm={3} xs={12}>
                            <TextField
                              size="small"
                              fullWidth
                              type="text"
                              name="swift_code"
                              label="SWIFT Code *"
                              {...sailorInfoHandler("swift_code")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['swift_code']}
                              helperText={sailorInfoErrors['swift_code'] ? sailorInfoErrors['swift_code'].message : ''}
                            />

                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              fullWidth
                              name="branch_address"
                              label="Branch Address *"
                              multiline
                              minRows={3}
                              maxRows={10}
                              {...sailorInfoHandler("branch_address")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['branch_address']}
                              helperText={sailorInfoErrors['branch_address'] ? sailorInfoErrors['branch_address'].message : ''}
                            />

                          </Grid>
                          <Grid item sm={3} xs={12}>
                            <div className="control-div">

                              <Button
                                variant="contained"
                                component="label"
                              >
                                Upload Cancelled Cheque
                                <input
                                  type="file"
                                  name="penny_check_verified"
                                  hidden
                                  {...sailorInfoHandler("penny_check_verified")}
                                />
                              </Button>
                            </div>
                            {cheque.error && <FormHelperText error={cheque.error}>{cheque.message}</FormHelperText>}
                          </Grid>

                        </Grid>
                      </Box>

                      <Typography variant="h6" mb={3} sx={{ color: "#333", textAlign: "left", }}>
                        Insurance Details
                      </Typography>
                      <Box mb={3.5}>
                        <Grid container spacing={3}>
                          <Grid item sm={3} xs={12}>
                            <TextField
                              fullWidth
                              type="text"
                              name="insurance_no"
                              size="small"
                              label="Insurance No *"
                              {...sailorInfoHandler("insurance_no")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['insurance_no']}
                              helperText={sailorInfoErrors['insurance_no'] ? sailorInfoErrors['insurance_no'].message : ''}
                            />

                          </Grid>

                          <Grid item sm={3} xs={12}>
                            <div className="control-div">
                              <Button
                                variant="contained"
                                component="label"
                              >
                                Upload Insurance Document
                                <input
                                  type="file"
                                  name="insurance_document"
                                  hidden
                                  {...sailorInfoHandler("insurance_document")}
                                />
                              </Button>
                              {insurance.error && <FormHelperText error={insurance.error}>{insurance.message}</FormHelperText>}
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                      <Typography variant="h6" mb={3} sx={{ color: "#333", textAlign: "left", }}>
                        Experience Details
                      </Typography>

                      <Box mb={3.5}>
                        <Grid container spacing={3}>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              fullWidth
                              type="text"
                              size="small"
                              name="post_held"
                              label="Last Position Held *"
                              {...sailorInfoHandler("post_held")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['post_held']}
                              helperText={sailorInfoErrors['post_held'] ? sailorInfoErrors['post_held'].message : ''}
                              
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              fullWidth
                              type="text"
                              name="last_held"
                              size="small"
                              label="No of Years Experience *"
                              {...sailorInfoHandler("last_held")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['last_held']}
                              helperText={sailorInfoErrors['last_held'] ? sailorInfoErrors['last_held'].message : ''}
                              
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                          <TextField
                              fullWidth
                              type="text"
                              name="last_held"
                              size="small"
                              label="Position Applying For *"
                              {...sailorInfoHandler("position_apply_for")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['position_apply_for']}
                              helperText={sailorInfoErrors['position_apply_for'] ? sailorInfoErrors['position_apply_for'].message : ''}
                              
                            />
                            {/* <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Position Applying For *</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                name="position_apply_for"
                                label="Position Applying For *"
                                {...sailorInfoHandler("position_apply_for")}
                                error={!!sailorInfoErrors['position_apply_for']}
                                helperText={sailorInfoErrors['position_apply_for'] ? sailorInfoErrors['position_apply_for'].message : ''}
                              >
                                <MenuItem value="Position-1">Position 1</MenuItem>
                                <MenuItem value="Position-2">Position 2</MenuItem>
                                <MenuItem value="Position-3">Position 3 </MenuItem>
                              </Select>
                              {sailorInfoErrors.marital_status && <FormHelperText error={!!sailorInfoErrors['marital_status']}>{sailorInfoErrors.marital_status.message}</FormHelperText>}
                            </FormControl> */}

                          </Grid>

                        </Grid>
                      </Box>
                      <Grid item sm={12} xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={6}>
                            <Typography variant="h6" mb={3} sx={{ color: "#333", textAlign: "left", }}>
                              Document(s)
                            </Typography>
                          </Grid>
                          {/* <Grid item xs={6} sx={{ textAlign: "right", }}>
                          <Button className="btn-grn" size="small" sx={{ textAlign: "right", }} onClick={() => append({ certificate: "", issue_authority: "", course_document: "",valid_date:"" })}>Add Row</Button>
                          </Grid> */}
                        </Grid>
                      </Grid>
                      <Box mb={3.5}>
                        <Grid container spacing={3}>
                          <Grid item sm={12} xs={12}>
                            {fields.map((item, index) => (
                              
                                <Grid container spacing={1} key={item.id}>

                                  <Grid item xs={3} >
                                    <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-label">Certificate Course Name *</InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="certificate *"
                                        size="small"
                                        {...sailorInfoHandler(`rows[${index}].certificate`, {
                                          required: 'Select Certificate'
                                        })}
                                        defaultValue={item.name}
                                        error={!!sailorInfoErrors?.rows?.[index]?.certificate}
                                        helperText={sailorInfoErrors?.rows?.[index]?.certificate ? sailorInfoErrors?.rows?.[index]?.certificate : ''}
                                      >
                                        
                                        {
                                         
                                          certificate_list.map((cons) => 
                                            <MenuItem value={cons.certificateName}>{cons.certificateName}</MenuItem>
                                          )
                                        }

                                      </Select>

                                      {sailorInfoErrors?.rows?.[index]?.certificate && <FormHelperText error={!!sailorInfoErrors?.rows?.[index]?.certificate}>{sailorInfoErrors?.rows?.[index]?.certificate.message}</FormHelperText>}
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={2} key={index}>
                                    <TextField
                                      fullWidth
                                      size="small"
                                      type="text"
                                      name="certificate_number"
                                      label="Certificate No *"
                                      {...sailorInfoHandler(`rows.${index}.certificate_number`, {
                                        required: 'Certificate Number Required'
                                      })}
                                      id="outlined-error"
                                      error={!!sailorInfoErrors?.rows?.[index]?.certificate_number}                        
                                    />
                                  {sailorInfoErrors?.rows?.[index]?.certificate_number && <FormHelperText error={!!sailorInfoErrors?.rows?.[index]?.certificate_number}>{sailorInfoErrors?.rows?.[index]?.certificate_number.message}</FormHelperText>}
                                  </Grid>
                                  <Grid item xs={2} key={index}>
                                  <TextField
                                      fullWidth
                                      size="small"
                                      type="text"
                                      name="issue_authority"
                                      label="Issuing Authority *"
                                      {...sailorInfoHandler(`rows.${index}.issue_authority`, {
                                        required: 'Issue Authority Required'
                                      })}
                                      id="outlined-error"
                                      error={!!sailorInfoErrors?.rows?.[index]?.issue_authority}
                                        helperText={sailorInfoErrors?.rows?.[index]?.issue_authority ? sailorInfoErrors?.rows?.[index]?.issue_authority.message : ''}

                                    />
                                    {/* <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-label">Issuing Authority *</InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        size="small"
                                        name="position_apply_for"
                                        label="Position Applying For *"
                                        {...sailorInfoHandler(`rows.${index}.issue_authority`, {
                                          required: 'Issue Authority Required'
                                        })}

                                        error={!!sailorInfoErrors?.rows?.[index]?.issue_authority}
                                        helperText={sailorInfoErrors?.rows?.[index]?.issue_authority ? sailorInfoErrors?.rows?.[index]?.issue_authority.message : ''}
                                      >
                                        <MenuItem value="Authority-1">Authority 1</MenuItem>
                                        <MenuItem value="Authority-2">Authority 2</MenuItem>
                                        <MenuItem value="Authority-3">Authority 3 </MenuItem>
                                      </Select>
                                      {sailorInfoErrors?.rows?.[index]?.issue_authority && <FormHelperText error={!!sailorInfoErrors?.rows?.[index]?.issue_authority}>{sailorInfoErrors?.rows?.[index]?.issue_authority.message}</FormHelperText>}
                                    </FormControl> */}

                                  </Grid>
                                  
                                  <Grid item xs={2}  key={index}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                                      <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                        <DatePicker
                                          label="Valid Till *"
                                          slotProps={{ textField: { size: 'small' } }}
                                          {...sailorInfoHandler(`rows.${index}.valid_date`)}
                                          onChange={(valid_date) => {
                                            setValue(`rows.${index}.valid_date`, valid_date); // Set the value using Formik's setFieldValue
                                          }}
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                    {sailorInfoErrors?.rows?.[index]?.valid_date && <FormHelperText error={!!sailorInfoErrors?.rows?.[index]?.valid_date}>{sailorInfoErrors?.rows?.[index]?.valid_date.message}</FormHelperText>}

                                  </Grid>
                                  <Grid item xs={2} key={index}>

                                    <div className="control-div" xs={3}>
                                      <Button
                                        variant="contained"
                                        component="label"
                                      >
                                        Upload Document
                                        <input
                                          type="file"
                                          name="course_document"
                                          hidden
                                          {...sailorInfoHandler(`rows.${index}.course_document`)}
                                          onChange={(course_document) => {
                                            setValue(`rows.${index}.course_document`, course_document.target.files[0]); // Set the value using Formik's setFieldValue
                                          }}
                                        />
                                      </Button>
                                      {/* {certificate_doc.error && <FormHelperText error={certificate_doc.error}>{certificate_doc.message}</FormHelperText>} */}
                                      {sailorInfoErrors?.rows?.[index]?.certificate_doc && <FormHelperText error={!!sailorInfoErrors?.rows?.[index]?.certificate_doc}>{sailorInfoErrors?.rows?.[index]?.certificate_doc.message}</FormHelperText>}
                                    </div>
                                    
                                  </Grid>
                                  <Grid item xs={1} key={index}>
                                  {/* <button type="button" className="btn-grn" size="small" style={{ "background-color": 'red' }} >
                                      D
                                    </button> */}

                                   <DeleteIcon className="text-danger" onClick={() => remove(index)}/>

                                    </Grid>

                                 

                                </Grid>
                              
                            ))}
                          </Grid>
                          <Grid item sm={2} xs={12}>
                            <Button className="btn-grn" size="small" sx={{ textAlign: "right", }} onClick={() => append({ certificate: "",certificate_number: "", issue_authority: "", course_document: "", valid_date: "" })}>Add Row</Button>
                          </Grid>
                        </Grid>
                      </Box>
                      
                      {
                        loader === true && (<Box sx={{ width: '100%' }}>
                          <div className="loading-div">
                            <div className="loading-inn">
                              <img src={ELogo} />
                              <LinearProgress />
                              <p>Processing Please Wait...</p>
                            </div>
                          </div>
                        </Box>)
                      }
                     

                      <ToastContainer />
                    </div>
                    <React.Fragment>
                      {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                      <Box sx={{ display: 'flex', flexDirection: 'row', padding: '10px 10px', background: "#f5f5f5" }}>
                        <Button className="btn-black"
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                          <Button className="btn-black" color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                            Skip
                          </Button>
                        )}

                        <Button type="submit" className="btn-org">
                          {activeStep === steps.length - 1 ? 'Finish' : 'Verify'}
                        </Button>
                      </Box>
                    </React.Fragment>
                  </form>
                </>
              ) : ""}



              {/* ====== step 7 starts  ====== */}
              {activeStep === 1 ? (
                <>
                  <div className="stepper-content">
                    <Box mb={3.5}>
                      <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                          <TableContainer component={Paper}>
                            <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Personal Information</StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>

                                <StyledTableRow sx={{ height: '50%' }}>
                                  <StyledTableCell component="th" scope="row">
                                    First Name
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.First_Name ? sailor_info.First_Name : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Middle Name
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.middle_name ? sailor_info.middle_name : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Last Name
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Last_Name ? sailor_info.Last_Name : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Date of Birth
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Date_of_Birth ? <Moment format="DD/MM/YYYY">
                                  {sailor_info.Date_of_Birth}
                                  </Moment> : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Place of Birth
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Place_of_Birth ? sailor_info.Place_of_Birth : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Marital Status
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Maritial_Status ? sailor_info.Maritial_Status : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Country
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Country ? sailor_info.Country : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Nationality
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Nationality ? sailor_info.Nationality : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Mobile No
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Phone ? sailor_info.Phone : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Address of Communication
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Corresponding_Address ? sailor_info.Corresponding_Address : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Permanent Address
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Permanent_Address ? sailor_info.Permanent_Address : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Emergency Contact Person
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Emergency_Contact_Person ? sailor_info.Emergency_Contact_Person : ""}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Emergency Contact Number
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Emergency_Contact_Number ? sailor_info.Emergency_Contact_Number : ""}</StyledTableCell>

                                </StyledTableRow>

                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <TableContainer component={Paper}>
                            <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Passport Info</StyledTableCell>
                                  <StyledTableCell align="right"><img src={bank_verified} style={{ width: '100px' }} /></StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>

                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Passport No
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Passport_Number ? sailor_info.Passport_Number : ""} </StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Place of Issue
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Place_Of_Issue ? sailor_info.Place_Of_Issue : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Date Of Issue
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Date_Of_Issue ? <Moment format="DD/MM/YYYY">
                                  {sailor_info.Date_Of_Issue}
                                  </Moment> : ""}</StyledTableCell>

                                </StyledTableRow>
                                
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TableContainer component={Paper}>
                            <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Bank Details</StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>

                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Account Holder Name
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Account_Name ? sailor_info.Account_Name : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Account No
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Account_Number ? sailor_info.Account_Number : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    IFSC Code
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.IFSC_Code ? sailor_info.IFSC_Code : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    SWIFT Code
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.SWIFT_Code ? sailor_info.SWIFT_Code : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Branch Address
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Branch_Code ? sailor_info.Branch_Code : ""}</StyledTableCell>

                                </StyledTableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TableContainer component={Paper}>
                            <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Experience Details</StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>

                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Last Post Held
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Last_Position_held ? sailor_info.Last_Position_held : ""}</StyledTableCell>

                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Year of Experience at last held
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{sailor_info.Years_Of_Experience ? sailor_info.Years_Of_Experience : ""}</StyledTableCell>

                                </StyledTableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TableContainer component={Paper}>
                            <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Documents</StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                </TableRow>

                              </TableHead>
                              <TableBody>

                                {sailor_info.Certificates.map((resp, index) => (
                                

                                  <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row">
                                      {resp.Course_Name}
                                    </StyledTableCell>
                                    <StyledTableCell align="right"> {resp.Issusing_Authority}</StyledTableCell>
                                    <StyledTableCell align="right"> {<Moment format="DD/MM/YYYY">
                                  {resp.Certificate_Valid_Till}
                                  </Moment>}</StyledTableCell>
                                    <StyledTableCell align="right"></StyledTableCell>

                                  </StyledTableRow>
                                 ))} 
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TableContainer component={Paper}>
                            <Table sx={{ maxWidth: 800 }} aria-label="customized table" size="small">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Passport Verification</StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                  <StyledTableCell align="right"></StyledTableCell>
                                </TableRow>

                              </TableHead>
                              <TableBody>
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    <img src={URL.createObjectURL(sailor_info.files[0])} style={{ width: '100px' }} />
                                  </StyledTableCell>
                                  <StyledTableCell align="center"><Alert severity="success" className="alert-message" sx={{ fontSize: "18px", width: "130px", color: "#2e7d32", border: "red" }}>Face Match Verified</Alert></StyledTableCell>
                                  <StyledTableCell align="right"><img src={URL.createObjectURL(sailor_info.files[0])} style={{ width: '100px' }} /></StyledTableCell>

                                </StyledTableRow>

                              </TableBody>
                            </Table>
                          </TableContainer>

                          {/* <FormControlLabel control={<Checkbox onChange={(event) => Agreeterms(event)}  />}  label={<Typography sx={{ fontSize: 16 }}>
                                            By checking this box, I verify the accuracy of the information provided.
                                </Typography>
                                      } />
                          {Agree === false && <FormHelperText error style={{"color":"red"}}>* Please agree to terms and conditions for OnBoarding</FormHelperText>} */}


                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <p className="agree"><FormControlLabel control={<Checkbox onChange={(event) => Agreeterms(event)} />} label="By checking this box, I verify the accuracy of the information provided" /></p>
                          {Agree === false && <FormHelperText error style={{ "color": "red" }}>* By checking this box, I verify the accuracy of the information provided</FormHelperText>}
                        </Grid>
                      </Grid>
                    </Box>

                  </div>

                  <React.Fragment>
                    {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}

                    <Box sx={{ display: 'flex', flexDirection: 'row', padding: '10px 10px', background: "#f5f5f5" }}>
                      <Button className="btn-black"
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {isStepOptional(activeStep) && (
                        ""
                      )}

                      <Button type="button" className="btn-org" onClick={AgreeNow}>
                        {activeStep === steps.length - 1 ? 'Onboard' : 'Next'}
                      </Button>
                    </Box>
                  </React.Fragment>
                </>
              ) : ""}
              {/* ====== step 7 ends  ====== */}

              {/* {activeStep === steps.length ? (
                <React.Fragment>
                  <div className="stepper-content">
                    <Typography sx={{
                      minHeight: "340px", textAlign: "center",
                      display: "flex", alignItems: "center", justifyContent: "center",
                    }}>

                      <Alert severity="success" className="alert-message" sx={{
                        fontSize: "20px", padding: "35px 30px", fontWeight: "600",
                        color: "#2e7d32",
                      }}>All steps completed - you're finished</Alert>

                    </Typography>
                  </div>
                  <Box sx={{ display: 'flex', flexDirection: 'row', padding: '10px 10px', background: "#f5f5f5" }}>
                    <Box sx={{ flex: '1 1 auto', }} />
                    <Button className="btn-black" onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : ("")} */}
            </Box>
          </div>
        </div>
      </section>

                    

				</div>
       
			</div>
     
		</div>
    <Footer></Footer>


      

   
   
    </>
  );
}

export default Registration;