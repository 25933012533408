import React, { useState, useEffect,useContext } from "react";
import Topnav from "../../common/topNav";
import { Link } from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";

import Ham from "../../assets/images/sailor/ham.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import { config } from "../../util/apiconfig";
import axios from 'axios';
import Navbar from "../Navbar";
import swal from 'sweetalert';
import Moment from 'react-moment';

function ManageAgent() {
    const obj = useContext(NoteContext)
    const [data , setData] = useState([]);
    async function getApprovedOnboardingList(){
        // let requestData = {
        //     "Application_Status": "Approved"
         // };


        let data = await fetch(config.mongo_endpoint+"api/v1/manningAgent/viewall", {
            method: "GET",
            headers: {
            "Content-type": "application/json",
            "x-access-token" : `${localStorage.getItem('AgentToken')}`
            // "x-access-token" : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiI2NGQxZGFhMWM4ZTE4MTEzODQ1YWY3N2QiLCJpYXQiOjE2OTE4NTIxNjV9.8et-s1ZMMB6NGuVQtiUCVgcopakWOSZmqN1Em3jqdAo"
            }           
        });
        let res = await data.json();
        console.log(res)
        if (res.status === "Success") {
            console.log("manage_agent", res.data)
            setData(res.data)
    
        } else {
            alert(res.message);
    
        }
        
    }
    useEffect(()=>{
        getApprovedOnboardingList()
    }, [])

    
  const deleteAgent = (AgentId) => {
  console.log(AgentId)

    swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
             axios.delete(config.mongo_endpoint+"api/v1/manningAgent/delete", {
                headers: {"Content-type": "application/json"} ,
                data : {"AgentId" : AgentId }
            })
            swal("Manning Account Information Deleted....!", {
                icon: "success",
              });
              getApprovedOnboardingList()
        } else {
            swal("Cancelled");
        }
      });

    console.log(localStorage.getItem('AgentToken'));
   

}

// const editAgent =(_id)=>{
//     console.log(_id)
// }

async function editAgent(row){
    console.log("ROW**", row)
    obj.setUpdate_agent(row)
    obj.setUpdatingFlog(1);
}

   

    return (
        <>

            <AgentMenu></AgentMenu>

            <div className="container-fluid pr-0" id="main-area">
                      
                <Navbar />
                
                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me-auto ">
                            <h2 className="page-title">Manage Agent </h2>
                        </div>
                        <div>
                        <Link to="/AddAgent" className="nav-link" >
                            <Button variant="contained" color="success">
  Add
</Button></Link></div>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                            <TableHead>
                                <TableRow>
                                    
                                    <TableCell align="center" style={{ background: '#ddd' }}># </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Email </TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>First Name</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Phone No</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>DOB</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Blood Group</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Country</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Nationality</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Company Name</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>User Type</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Action</TableCell>



                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row,index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {index + 1}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {row.Email}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {row.First_Name}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.Phone} </TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{<Moment format="DD/MM/YYYY">{row.Date_of_Birth}</Moment>} </TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.Blood_Group} </TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {row.Country}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {row.Nationality}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> {row.CompanyName}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> { (row.Type === 1) ? "Admin" : "Agent"} </TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>   <DeleteIcon onClick={() => {deleteAgent(row._id)}} /> 
                                        {/* <EditIcon onClick={() => {editAgent(row._id)}} /> */}
                                        <Link to="/UpdateAgent" onClick={()=>{editAgent(row)}}><EditIcon /></Link>
                                        
                                     
 </TableCell>
                                       


                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                </div>
          





        </>
    );
}

export default ManageAgent;