import React, { useState, useEffect } from "react";
import Topnav from "../../common/topNav";
import { Link } from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";
import ELogo from "../../assets/images/sailor/logo.svg";
import ELogo1 from "../../assets/images/sailor/sailoronboard.svg";
import Mico1 from "../../assets/images/sailor/dash-icon.svg";
import Mico2 from "../../assets/images/sailor/boar-icon.svg";

import Dash1 from "../../assets/images/sailor/dash-ico-1.svg";
import Dash2 from "../../assets/images/sailor/dash-ico-2.svg";
import Dash3 from "../../assets/images/sailor/dash-ico-3.svg";
import Dash4 from "../../assets/images/sailor/dash-ico-4.svg";
import Ham from "../../assets/images/sailor/ham.svg";
import AgentMenu from "../../components/AgentMenu";
import { useContext } from "react";
import NoteContext from "../../context/NoteContext";
import Navbar from "../Navbar";
import axios from 'axios';
import { config } from "../../util/apiconfig";





function Manningagentdashboard() {
    const [bannerConfig, setbannerConfig] = useState({ bannerType: 3 });
    const obj = useContext(NoteContext);
    console.log(obj)
    const [data, setData] = useState([]);
    useEffect(()=>{
        axios.get(config.mongo_endpoint+'api/v1/dashboard/report').then((resDash)=>{
            console.log(resDash)
            let datas=[];
            const resultData =resDash.data;
            console.log(resultData)
            datas.push(resultData)
            setData(datas)
        })
    }, [])
    console.log(data)
    return (
        <>

            <AgentMenu></AgentMenu>

            <div class="container-fluid pr-0" id="main-area">
                <Navbar/>
                <div className="container-fluid">
                <div class="d-flex border-bottom pb-15 pt-15">
                    <div class="me-auto ">
                        <h2 class="page-title">Agent Dashboard​</h2>
                         
                    </div>

                </div>
                {/* {data.map((resReu)=>{
                        console.log(resReu)
                })} */}
                {data.map((resReu, index)=>(
                    <div class="row g-6 mb-6">
                    <div class="col-xl-3 col-sm-6 col-12">
                        <div class="card  border-0">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <span class="font-semibold text-sm d-block mb-2">Total No Registration</span>
                                        <span class="h3 font-bold mb-0">{resReu.totalRegisteredCount}</span>
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon icon-shape  text-white text-lg rounded-circle">
                                            <img src={Dash1} alt="" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-12">
                        <div class="card  border-0">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <span class="font-semibold text-sm d-block mb-2">Today Registrations</span>
                                        <span class="h3 font-bold mb-0">{resReu.todayRegisteredCount}</span>
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon icon-shape  text-white text-lg rounded-circle">
                                            <img src={Dash2} alt="" />

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-sm-6 col-12">
                        <div class="card  border-0">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <span class="font-semibold text-sm d-block mb-2">Approved Onboarding</span>
                                        <span class="h3 font-bold mb-0">{resReu.approvedCount}</span>
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon icon-shape  text-white text-lg rounded-circle">
                                            <img src={Dash3} alt="" />

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div class="col-xl-3 col-sm-6 col-12">
                        <div class="card  border-0">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <span class="font-semibold text-sm d-block mb-2">Pending Onboard</span>
                                        <span class="h3 font-bold mb-0">{resReu.pendingCount}</span>
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon icon-shape  text-white text-lg rounded-circle">
                                            <img src={Dash3} alt="" />

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-12 mt-4">
                        <div class="card  border-0">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <span class="font-semibold text-sm d-block mb-2">Total Rejected</span>
                                        <span class="h3 font-bold mb-0">{resReu.rejectCount}</span>
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon icon-shape text-white text-lg rounded-circle">
                                            <img src={Dash4} alt="" />

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                ))}
               
                </div>
            </div>





        </>
    );
}

export default Manningagentdashboard;