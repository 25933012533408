import React from 'react'
import { useContext } from 'react'
import NoteContext from '../../context/NoteContext'
import Ham from "../../assets/images/sailor/ham.svg";

export default function Navbar() {
    const obj = useContext(NoteContext)  
  return (
    <div class="main-header">

        <div class="main-header-left ">
            {/* <img src={Ham} alt="" /> */}

        </div>
        {}
        <div class="main-header-right">
            <p>Welcome, <span>{obj.name}</span></p>
        </div>

    </div>
  )
}
