import React, { useState, useEffect, useContext } from "react";
import Topnav from "../../common/topNav";
import { Link } from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";
import Box from '@mui/material/Box';
import Ham from "../../assets/images/sailor/ham.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AgentMenu from "../../components/AgentMenu";
import NoteContext from "../../context/NoteContext";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import { FormHelperText, Grid, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FormLabel from '@mui/material/FormLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import PhoneIcon from "@mui/icons-material/Phone";
import axios from 'axios';
import { config } from "../../util/apiconfig";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Navbar from "../Navbar";
import dayjs from "dayjs";
const countrycitystateHandler = require('countrycitystatejson');




function UpdateAgent() { 
  // const { AgentId } = match.params;
  // const isAddMode = !AgentId;

    
    // =================== Voter Info Tab-1 Validation  ================

  const sailorInformation = yup.object().shape({
    first_name: yup.string().required("First Name Required").matches(/^[A-Za-z\s]*$/,"Only Alphabets allowed"),
    last_name: yup.string().required("Last Name Required").matches(/^[A-Za-z\s]*$/,"Only Alphabets allowed"),
    // middle_name: yup.string().required("Middle Name Required").matches(/^[A-Za-z\s]*$/,"Only Alphabets allowed"),
    date_of_birth: yup.string().required("Date of birth Required"),    
    blood_group: yup.string().required("Blood Group Required"),
    marital_status: yup.string().required("Marital Status Required"),
    country: yup.string().required("Country Required"),
    nationality: yup.string().required("Nationality Required"),
    company_name: yup.string().required("Company Name Required"),
    user_type: yup.string().required("User Type Required"),
    mobile_number: yup
          .string()
          .required("Phone Number is required")
          .matches(
            /^[0-9]{10,15}$/,
            'Phone number must be between 10 to 15 digits'
          ), // Use regex pattern to validate phone number format,
    emergency_person: yup.string().required("Emergency Contact Person Required"),
    emergency_number: yup.string().required("Emergency Contact Number Required"),
    address: yup.string().required("Address Required"),
    permanent_address: yup.string().required("Permanent Address Required"),
      email_Id: yup.string().required("Email Id Required"),
      password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters'),  
    
      }) 
      
       // =================== Voter Info Tab-1 Validation  ================
  // =================== Voter Info Yup Resolver Starts  ================
  const { register: sailorInfoHandler,
    handleSubmit: sailorInfoHandlerSubmit,
    reset: voterInfoReset,   
    setValue,
    formState: { errors: sailorInfoErrors } } = useForm({
      resolver: yupResolver(sailorInformation),
      mode: 'OnSubmit',      
    });

//   console.log(sailorInfoErrors)
   

    const [age, setAge] = React.useState('');
    const [state_list, setStatelist] = useState([])
    const [activeStep, setActiveStep] = React.useState(0);
    const [Agree, setAgree] = useState('')
    const [loader, setLoader] = useState(false)
    const [user, setUser] = useState({});
    const [dob, setDob] = React.useState(dayjs(''));
    const [blood, setBlood] = React.useState('');
    const [maritalStatus, setmaritalStatus] = React.useState('');
    const [country, setCountry] = useState('');
    const [country_list, setCountrylist] = useState([]);
    const [userType, setUserType] = useState('');
    const [id, setId] = useState('');
    useEffect(() => {       
        getCountryList();
      }, []);

      

    const handleChange = (event) => {
        setAge(event.target.value);
    };


    const obj = useContext(NoteContext)
    const [data, setData] = useState([]);
    const [update_agent, setUpdate_agent] = useState(obj.update_agent)
      console.log("UPDATEAGENT=>", update_agent)
    async function getApprovedOnboardingList() {
        let requestData = {
            "Application_Status": "Approved"
        };

        let data = await fetch(config.mongo_endpoint+"api/v1/application/report", {
            method: "GET",
            headers: {
                "Content-type": "application/json",
                "x-access-token": `${obj.token}`
            },
            body: JSON.stringify(requestData)
        });
        let res = await data.json();
        if (res.status === "Success") {

            setData(res.data)

        } else {
            // alert(res.message);
        }

    }
    useEffect(() => {
        getApprovedOnboardingList()
    }, [])

    const navigate = useNavigate();
  const LandingScreen = () => {
    navigate("/ManageAgent");
  }
   
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };

      const handleDateChange = (formdate) => {
    if (formdate) {
      const date = new Date(formdate);
      // Format the date to YYYY-MM-DD
      const formattedDate = date.toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
      }).split('/').reverse().join('-');
      return formattedDate;
    }
}

useEffect(()=>{
    setValue('first_name', update_agent.First_Name);
    setValue('last_name', update_agent.Last_Name);
    setValue('email_Id', update_agent.Email);
    setValue('password', update_agent.Password);
    setValue('middle_name', update_agent.Middle_Name);
    setValue('date_of_birth', update_agent.Date_of_Birth);
    setDob(dayjs(handleDateChange(update_agent.Date_of_Birth)));
    
    setBlood(update_agent.Blood_Group);
    setValue('blood_group', update_agent.Blood_Group);
    setmaritalStatus(update_agent.Maritial_Status);
    setValue('marital_status', update_agent.Maritial_Status);
    setCountry(update_agent.Country);
    setValue('country', update_agent.Country);
    setValue('nationality', update_agent.Nationality);
    setValue('mobile_number', update_agent.Phone);
    setValue('emergency_person', update_agent.Emergency_Contact_Person);
    setValue('emergency_number', update_agent.Emergency_Contact_Number);
    setValue('company_name', update_agent.CompanyName);
    setValue('user_type', update_agent.Type);
    setUserType(update_agent.Type);
    setValue('address', update_agent.Corresponding_Address);
    setValue('permanent_address', update_agent.Permanent_Address);
    setValue('_id', update_agent._id);
    setId(update_agent._id);   
}, [])
      

      const onSubmit = async(data) =>{
        try{
          const agentInformation = {
              "_id":id,
              "Email" : data["email_Id"],
              "Password" : data["password"],
              "First_Name" : data["first_name"],
              "Middle_Name" : data["middle_name"],
              "Last_Name" : data["last_name"],
              "Date_of_Birth" : handleDateChange(data["date_of_birth"]),            
              "Blood_Group" :data["blood_group"],
              "Maritial_Status" :data["marital_status"],
              "Country" :data["country"],
              "CompanyName":data["company_name"],
              "Type":data["user_type"],
              "Nationality" :data["nationality"],
              "Phone" :data["mobile_number"],
              "Emergency_Contact_Person" :data["emergency_person"],    
              "Emergency_Contact_Number" :data["emergency_number"],        
              "Corresponding_Address" :data["address"],
              "Permanent_Address" :data["permanent_address"],          
            }
          await axios.post(config.mongo_endpoint+'api/v1/manningAgent/update', agentInformation, { headers: { 'Content-Type': 'application/json'} })
          .then(result => {
              console.log(result);
              if(result.status == 200 && result.data.status == 'Success'){
                  toast.success("Registration Data Updated Successfully.", {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                  });  
                  LandingScreen();
                } else {
                  toast.success("Duplicate Data Exist", {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                  });  
                }
          })
          
  
            
  
                  //   setsailor_formdata_info(sailor_request);
                  //   handleNext()
                  //   console.log("sailor_info",sailor_info);
                  
         }
         catch (error) {
          console.error(error);
          // if (error)
          setLoader(false);
          if (error) {
            alert("Something went wrong. Please try again Later....")
          }
          console.log(error.code);
         
        }

      }      
      
         

      // async function updateUser(data) {
      //   try{
      //    const agentInformation = {
      //        "Email" : data["email_Id"],
      //        "Password" : data["password"],
      //        "First_Name" : data["first_name"],
      //        "Middle_Name" : data["middle_name"],
      //        "Last_Name" : data["last_name"],
      //        "Date_of_Birth" : handleDateChange(data["date_of_birth"]),            
      //        "Blood_Group" :data["blood_group"],
      //        "Maritial_Status" :data["marital_status"],
      //        "Country" :data["country"],
      //        "CompanyName":data["company_name"],
      //        "Type":data["user_type"],
      //        "Nationality" :data["nationality"],
      //        "Phone" :data["mobile_number"],
      //        "Emergency_Contact_Person" :data["emergency_person"],    
      //        "Emergency_Contact_Number" :data["emergency_number"],        
      //        "Corresponding_Address" :data["address"],
      //        "Permanent_Address" :data["permanent_address"],
      //        "AgentId" :data["_id"],        
      //      }
      //    await axios.post(config.mongo_endpoint+'api/v1/manningAgent/update', agentInformation, { headers: { 'Content-Type': 'application/json'} })
      //    .then(result => {
      //        console.log(result);
      //        if(result.status == 200){
      //            toast.success("Agent Updated Successfully.", {
      //              position: toast.POSITION.TOP_CENTER,
      //              theme: "colored",
      //            });  
      //            LandingScreen();
      //          } else {
      //            toast.success("Duplicate Data Exist", {
      //              position: toast.POSITION.TOP_CENTER,
      //              theme: "colored",
      //            });  
      //          }
      //    })
         
 
           
 
      //            //   setsailor_formdata_info(sailor_request);
      //            //   handleNext()
      //            //   console.log("sailor_info",sailor_info);
                 
      //   }
      //   catch (error) {
      //    console.error(error);
      //    // if (error)
      //    setLoader(false);
      //    if (error) {
      //      // alert("Something went wrong. Please try again Later....")
      //    }
      //    console.log(error.code);
        
      //  }
      //  }    

    //   const getStateList = () => {
    //     let data = countrycitystateHandler.getStatesByShort('IN');
    //     console.log(data);
    //     let obj={};
    //     let stateListData=[];
    //     data.map((item,index) => {
    //       obj={
    //         value: `${item}`,
    //         label: `${item}`
    //       };
    //       stateListData.push(obj);
    //     })
    //     setStatelist(stateListData);
    //   }
    const getCountryList = () => {
        let data = countrycitystateHandler.getCountries();
        console.log(data);
        let obj={};
        let countryListData=[];
        data.forEach((country, index) => {
          obj={
            value: `${country.name}`,
            label: `${country.name}`
          };
          countryListData.push(obj);
        });
        setCountrylist(countryListData);
      }
      

    //   useEffect(async() => {
    //     if (!isAddMode) {
    //         // get user and set form fields
    //         await axios.post(config.mongo_endpoint+'api/v1/manningAgent/update', { headers: { 'Content-Type': 'application/json'} }).then(user => {                                  
    //             const fields = ['email_Id', 'password', 'first_name', 'middle_name', 'last_name', 'date_of_birth', 'blood_group', 'marital_status', 'country', 'nationality', 'mobile_number', 'emergency_person', 'emergency_number', 'company_name', 'user_type', 'address', 'permanent_address'];
    //             fields.forEach(field => setValue(field, user[field]));
    //             setUser(user);
    //         });
    //     }
    // }, []);
    
        

    return (
        <>

            <AgentMenu></AgentMenu>

            <div className="container-fluid pr-0" id="main-area">
            <Navbar />                
                 {/* ====== step 0 starts  ====== */}
              
                <form onSubmit={sailorInfoHandlerSubmit(onSubmit)}>
                <div className="container-fluid">
                    <div className="d-flex border-bottom pb-15 pt-15">
                        <div className="me-auto ">
                            <h2 className="page-title">Update Agent</h2>
                        </div>
                        <div> </div>
                    </div>
                    <Grid container spacing={3} marginTop={1}>
                        <Grid item sm={3} xs={12}>
                        <input type="hidden" name="_id" value="hiddenValue" {...sailorInfoHandler("_id")} />                        
                        <TextField
                              size="small"
                              fullWidth
                              type="text"
                              name="email_Id"
                              label="Email ID *"
                              ref={sailorInfoHandler}
                              {...sailorInfoHandler("email_Id")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['email_Id']}
                              helperText={sailorInfoErrors['email_Id'] ? sailorInfoErrors['email_Id'].message : ''}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                        <TextField
                              size="small"
                              fullWidth
                              type="password"
                              name="password"
                              label="Password *"
                              {...sailorInfoHandler("password")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['password']}
                              helperText={sailorInfoErrors['password'] ? sailorInfoErrors['password'].message : ''}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                        <TextField
                              size="small"
                              fullWidth
                              type="text"
                              name="first_name"
                              label="First Name *"
                              {...sailorInfoHandler("first_name")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['first_name']}
                              helperText={sailorInfoErrors['first_name'] ? sailorInfoErrors['first_name'].message : ''}
                            />
                        </Grid>

                        <Grid item sm={3} xs={12}>
                        <TextField
                              fullWidth
                              size="small"
                              type="text"
                              name="middle_name"
                              label="Middle Name"
                              {...sailorInfoHandler("middle_name")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['middle_name']}
                              helperText={sailorInfoErrors['middle_name'] ? sailorInfoErrors['middle_name'].message : ''}
                              
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} marginTop={1}>
                        <Grid item sm={3} xs={12}>
                        <TextField
                              fullWidth
                              type="text"
                              size="small"
                              name="last_name"
                              label="Last Name *"
                              {...sailorInfoHandler("last_name")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['last_name']}
                              helperText={sailorInfoErrors['last_name'] ? sailorInfoErrors['last_name'].message : ''}
                              
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                <DatePicker
                                  label="Date of Birth *"
                                  value={dob}
                                  {...sailorInfoHandler("date_of_birth")}
                                  slotProps={{ textField: { size: 'small' } }}
                                  onChange={(date_of_birth) => {
                                    setValue('date_of_birth', date_of_birth); 
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                            {sailorInfoErrors.date_of_birth && <FormHelperText error={!!sailorInfoErrors['date_of_birth']}>{sailorInfoErrors.date_of_birth.message}</FormHelperText>}
                        </Grid>
                        <Grid item sm={3} xs={12}>
                        <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Blood Group *</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={blood}
                                size="small"
                                name="blood_group"
                                label="Blood Group *"
                                {...sailorInfoHandler("blood_group")}
                                onChange={(blood_group)=>{
                                    
                                    setBlood(blood_group.target.value);
                                }}
                                error={!!sailorInfoErrors['blood_group']}
                                helperText={sailorInfoErrors['blood_group'] ? sailorInfoErrors['blood_group'].message : ''}
                              >
                                <MenuItem value="1">A+</MenuItem>
                                <MenuItem value="2">O+</MenuItem>
                                <MenuItem value="3">B+</MenuItem>
                                <MenuItem value="4">AB+</MenuItem>
                                <MenuItem value="5">A-</MenuItem>
                                <MenuItem value="5">O-</MenuItem>
                                <MenuItem value="6">B-</MenuItem>
                                <MenuItem value="7">AB-</MenuItem>
                                {/* <MenuItem value="A+">A+</MenuItem>
                                <MenuItem value="O+">O+</MenuItem>
                                <MenuItem value="B+">B+</MenuItem>
                                <MenuItem value="AB+">AB+</MenuItem>
                                <MenuItem value="A-">A-</MenuItem>
                                <MenuItem value="O-">O-</MenuItem>
                                <MenuItem value="B-">B-</MenuItem>
                                <MenuItem value="AB-">AB-</MenuItem> */}
                              </Select>
                              {sailorInfoErrors.blood_group && <FormHelperText error={!!sailorInfoErrors['blood_group']}>{sailorInfoErrors.blood_group.message}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        <Grid item sm={3} xs={12}>
                        <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Marital Status*</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                value={maritalStatus}
                                name="marital_status"
                                label="Marital Status *"
                                {...sailorInfoHandler("marital_status")}
                                onChange={(marital_status)=>{
                                    setmaritalStatus(marital_status.target.value);
                                }}
                                error={!!sailorInfoErrors['marital_status']}
                                helperText={sailorInfoErrors['marital_status'] ? sailorInfoErrors['marital_status'].message : ''}
                              >
                                <MenuItem value="1">Single</MenuItem>
                                <MenuItem value="2">Married</MenuItem>
                                <MenuItem value="3">Widowed </MenuItem>
                                <MenuItem value="3">Divorced </MenuItem>
                                {/* <MenuItem value="Single">Single</MenuItem>
                                <MenuItem value="Married">Married</MenuItem>
                                <MenuItem value="Widowed">Widowed </MenuItem>
                                <MenuItem value="Divorced">Divorced </MenuItem> */}
                              </Select>
                              {sailorInfoErrors.marital_status && <FormHelperText error={!!sailorInfoErrors['marital_status']}>{sailorInfoErrors.marital_status.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} marginTop={1}>
                    <Grid item sm={3} xs={12}>
                    <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Country *</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                value={country}
                                name="country"
                                label="Country *"
                                {...sailorInfoHandler("country")}
                                onChange={(country)=>{
                                    // setValue('country', country);
                                    setCountry(country.target.value)
                                }}
                                error={!!sailorInfoErrors['country']}
                                helperText={sailorInfoErrors['country'] ? sailorInfoErrors['country'].message : ''}
                              >
                                <MenuItem value="Country">Country</MenuItem>
                                {
                                  country_list.map((list) => (
                                    <MenuItem value={list.value}>{list.label}</MenuItem>
                                  ))
                                }

                              </Select>
                              {sailorInfoErrors.country && <FormHelperText error={!!sailorInfoErrors['country']}>{sailorInfoErrors.country.message}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item sm={3} xs={12}>
                        <TextField
                              fullWidth
                              type="text"
                              size="small"
                              name="nationality"
                              label="Nationality *"
                              {...sailorInfoHandler("nationality")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['nationality']}
                              helperText={sailorInfoErrors['nationality'] ? sailorInfoErrors['nationality'].message : ''}
                                                         />
                        </Grid>
                        <Grid item sm={3} xs={12}>

                        <TextField                           
                            fullWidth
                            size="small"
                            type="text"
                            name="mobile_number"
                            label="Phone *"
                            {...sailorInfoHandler("mobile_number")}
                            id="outlined-error"
                            error={!!sailorInfoErrors['mobile_number']}
                            helperText={sailorInfoErrors['mobile_number'] ? sailorInfoErrors['mobile_number'].message : ''}
                            inputProps={{ maxLength: 10 }}
                          />                                    
                        </Grid>
                        <Grid item sm={3} xs={12}>
                        <TextField
                              fullWidth
                              size="small"
                              type="text"
                              name="emergency_person"
                              label="Emergency Contact Person *"
                              {...sailorInfoHandler("emergency_person")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['emergency_person']}
                              helperText={sailorInfoErrors['emergency_person'] ? sailorInfoErrors['emergency_person'].message : ''}
                              inputProps={{ maxLength: 25 }}
                            />
                        </Grid>

                       
                    </Grid>

                    <Grid container spacing={3} marginTop={1}>
                    
                        <Grid item sm={4} xs={12}>
                        <TextField
                              fullWidth
                              size="small"
                              type="text"
                              name="emergency_number"
                              label="Emergency Contact Number *"
                              {...sailorInfoHandler("emergency_number")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['emergency_number']}
                              helperText={sailorInfoErrors['emergency_number'] ? sailorInfoErrors['emergency_number'].message : ''}
                              inputProps={{ maxLength: 10 }}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                        <TextField
                              fullWidth
                              size="small"
                              type="text"
                              name="company_name"
                              label="Company Name *"
                              {...sailorInfoHandler("company_name")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['company_name']}
                              helperText={sailorInfoErrors['company_name'] ? sailorInfoErrors['company_name'].message : ''}                             
                            />                           
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">User Type</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                name="user_type"
                                label="User Type *"
                                value={userType}  

                                {...sailorInfoHandler("user_type")}
                                onChange={(user_type)=>{
                                    setValue('user_type', user_type);
                                    setUserType(user_type.target.value);
                                }}
                                error={!!sailorInfoErrors['user_type']}
                                helperText={sailorInfoErrors['user_type'] ? sailorInfoErrors['user_type'].message : ''}
                              >
                                <MenuItem value="1">Admin</MenuItem>
                                <MenuItem value="2">Agent</MenuItem>                               
                              </Select>
                              {sailorInfoErrors.user_type && <FormHelperText error={!!sailorInfoErrors['user_type']}>{sailorInfoErrors.user_type.message}</FormHelperText>}
                             </FormControl>
                        </Grid>
                       
                    </Grid>
                    <Grid container spacing={3} marginTop={1}>
                    
                    <Grid item sm={6} xs={12}>
                    <TextField
                              fullWidth
                              name="address"
                              label="Corresponding Address *"
                              multiline
                              minRows={3}
                              maxRows={10}
                              {...sailorInfoHandler("address")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['address']}
                              helperText={sailorInfoErrors['address'] ? sailorInfoErrors['address'].message : ''}
                            />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                    <TextField
                              fullWidth
                              name="permanent_address"
                              label="Permanent Address*"
                              multiline
                              minRows={3}
                              maxRows={10}
                              {...sailorInfoHandler("permanent_address")}
                              id="outlined-error"
                              error={!!sailorInfoErrors['permanent_address']}
                              helperText={sailorInfoErrors['permanent_address'] ? sailorInfoErrors['permanent_address'].message : ''}
                            />
                    </Grid> 
                    <React.Fragment>
                      {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                      <Box sx={{ display: 'flex', flexDirection: 'row', padding: '10px 10px', background: "#f5f5f5" }}>
                        {/* <Button type="submit" className="btn-org">
                          SUBMIT
                        </Button> */}
                        <Button type="submit" variant="contained" color="success" sx={{marginTop:"20px", marginLeft:"18px"}}>
                        SUBMIT
</Button>
                      </Box>
                    </React.Fragment>   
                </Grid>
                </div>
                </form>
            </div>
        </>
    );
}

export default UpdateAgent;