import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom"; // import react router dom


import HomePage from "./views/homePage/homepage";
import ContactPage from "./views/contactPage";
import Registration from "./views/SailorRegistration";
import LandingPage from "./views/votingLanding";
import Dashboard from "./views/Dashboard";
import Manningagentdashboard from "./views/Manningagentdashboard";
import Login from "./views/login";
import Manninglogin from "./views/manning-login";
import Approvedonboarding from "./views/Approved-onboarding";
import Pendingonboarding from "./views/Pending-onboarding";
import Sailordashboard from "./views/Sailordashboard";
import Sailorhistory from "./views/Sailor-history";
import Updatedetails from "./views/Update-details";
// import Registermain from "./views/register-main";
import ViewOnboard from "./views/View-onboard";
import Viewapplication from "./views/view-application";
import ManageAgent from "./views/Manage-agent";
import AddAgent from "./views/Add-agent";
import ForgetPassword from "./views/forget-password";
import ResetPassword from "./views/reset-password";
import AddSailorhistory from "./views/Add-Sailor-history";
import UpdateSailor from "./views/Add-Sailor-history/UpdateSailor";
import UpdateAgent from "./views/Add-agent/UpdateAgent";




function App() {


  return (
    <BrowserRouter>
      <Routes>
             
        {/* <Route path="contact" element={<ContactPage />} />
        <Route path="voting" element={<LandingPage/>} />       
        <Route path="evoting/:id" element={<VotingPage/>} />  */}

        <Route path="/" element={<Login />} />  
        <Route path="/dashboard" element={<Dashboard />} />   
        <Route path="/onboarding" element={<Registration/>} />  
        <Route path="/signup" element={<Registration />} />
        <Route path="/Manningagentdashboard" element={<Manningagentdashboard />} />     
        <Route path="/Manninglogin" element={<Manninglogin />} />   
        <Route path="/Approvedonboarding" element={<Approvedonboarding />} />    
        <Route path="/Pendingonboarding" element={<Pendingonboarding />} /> 
        <Route path="/Sailordashboard" element={<Sailordashboard />} />     
        <Route path="/Sailorhistory" element={<Sailorhistory />} />     
        <Route path="/Updatedetails" element={<Updatedetails />} />  
        {/* <Route path="/Registermain" element={<Registermain />} />   */}
        <Route path="/ViewOnboard" element={<ViewOnboard />} /> 
        <Route path="/viewapplication" element={<Viewapplication />} />  
        <Route path="/ManageAgent" element={<ManageAgent />} />  
        <Route path="/AddAgent" element={<AddAgent />} />  
        <Route path="/ForgetPassword" element={<ForgetPassword />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />  
        <Route path="/AddSailorhistory" element={<AddSailorhistory />} /> 
        <Route path="/UpdateSailor" element={<UpdateSailor/>} />
        <Route path="/UpdateAgent" element={<UpdateAgent/>} />
        
        



         








      </Routes>
    </BrowserRouter>

  );
}

export default App;
