import React, { useState, useEffect } from "react";
import Topnav from "../../common/topNav";
import { Link ,useNavigate} from "react-router-dom";
import SideMenu from "../../components/SideMenu";
import ELogo from "../../assets/images/sailor/logo.svg";
import ELogo1 from "../../assets/images/sailor/sailoronboard.svg";
import Mico1 from "../../assets/images/sailor/dash-icon.svg";
import Mico2 from "../../assets/images/sailor/boar-icon.svg";

import Dash1 from "../../assets/images/sailor/dash-ico-1.svg";
import Dash2 from "../../assets/images/sailor/dash-ico-2.svg";
import Dash3 from "../../assets/images/sailor/dash-ico-3.svg";
import Dash4 from "../../assets/images/sailor/dash-ico-4.svg";
import Ham from "../../assets/images/sailor/ham.svg";
import NoteContext from "../../context/NoteContext";
import { useContext } from "react";
import Navbar from "../Navbar";
import { useSession } from '../../SessionContext';
import Login from "../login";



function Sailordashboard() {
    const obj = useContext(NoteContext)
    console.log(obj)
    const { loggedIn, login } = useSession();
    const navigate = useNavigate();

    console.log(loggedIn)
  

    return (
        <>
            {/* {loggedIn ? (<> */}
            <SideMenu></SideMenu>

            <div class="container-fluid pr-0" id="main-area">
                <Navbar />
                <div className="container-fluid">
                <div class="d-flex border-bottom pb-15 pt-15">
                    <div class="me-auto ">
                        <h2 class="page-title">Sailor Dashboard​</h2>
                        
                    </div>

                </div>
                <div class="row g-6 mb-6">
                    <div class="col-xl-3 col-sm-6 col-12">
                        <div class="card  border-0">
                        <a href="viewapplication" className="text-decoration-none">
                            <div class="card-body">
                                <div class="row">
                                    
                                    <div class="col">
                                        <span class="font-semibold text-sm d-block mb-2">View Application History</span>
                                        
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon icon-shape  text-white text-lg rounded-circle">
                                            <img src={Dash1} alt="" />
                                        </div>
                                    </div>
                                  
                                </div>

                            </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-12">
                        <div class="card  border-0">
                        <a href="Updatedetails" className="text-decoration-none">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                    <span class="font-semibold text-sm d-block mb-2">Update your details</span>
                                        
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon icon-shape  text-white text-lg rounded-circle">
                                            <img src={Dash2} alt="" />

                                        </div>
                                    </div>
                                </div>

                            </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-12">
                        <div class="card  border-0">
                        <a href="Sailorhistory" className="text-decoration-none">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <span class="font-semibold text-sm d-block mb-2">View Sailing History</span>
                                        
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon icon-shape  text-white text-lg rounded-circle">
                                            <img src={Dash3} alt="" />

                                        </div>
                                    </div>
                                </div>

                            </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-12">
                        <div class="card  border-0">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <span class="font-semibold text-sm d-block mb-2">Download Document</span>
                                       
                                    </div>
                                    <div class="col-auto">
                                        <div class="icon icon-shape text-white text-lg rounded-circle">
                                            <img src={Dash4} alt="" />

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        {/* </>) : (<Login></Login>)} */}




        </>
    );
}

export default Sailordashboard;