import React, { useState, useEffect, useContext } from "react";
import SideMenu from "../../components/SideMenu";
import { Link } from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";
import Ham from "../../assets/images/sailor/ham.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NoteContext from "../../context/NoteContext";
import Navbar from "../Navbar";
import Button from '@mui/material/Button';
import { config } from "../../util/apiconfig";
import Moment from 'react-moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

function Sailorhistory() {
   const obj = useContext(NoteContext)
   useEffect(()=>{
    findHistory()
},[])
    const [data , setData] = useState([])
    const navigate = new useNavigate();
    async function findHistory(){
        let data = await fetch(config.mongo_endpoint+"api/v1/sailorUser/sailorHistory/findAll", {
            method: "GET",
            headers: {
            "Content-type": "application/json",
            "x-access-token" : `${localStorage.getItem('Token')}`
            }
        });
        let res = await data.json();
        if (res.status === "Success") {
            console.log("sailor_history",res.data)
            setData(res.data)
    
        } else {
            alert(res.message);
    
        }
        
    }

    async function editAgent(row){
        console.log("ROW", row)
            obj.setsailor_info(row);
            obj.setonboardingFlag(1);
    }

    

    return (
        <>

            <SideMenu></SideMenu>

            <div class="container-fluid pr-0" id="main-area">
                <Navbar/>
                <div className="container-fluid">
                    <div class="d-flex border-bottom pb-15 pt-15">
                        <div class="me-auto ">
                            <h2 class="page-title">Sailor History</h2>
                            
                        </div>
                        <div>
                            <p>Onboarding ID: <b>{localStorage.getItem('onBoardingID')}</b></p>
                            <Link to="/AddSailorhistory" >
                            
                            <Button variant="contained" color="success">
                            Add
                        </Button>
                        </Link>
                        </div>

                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ background: '#ddd' }}>S.No</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Onboarding ID</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Sailor From</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Sailor To</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Ship Type</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>IMo No</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>End Date</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Status</TableCell>
                                    <TableCell align="center" style={{ background: '#ddd' }}>Action</TableCell>

                                 



                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row) => (
                                    <TableRow
                                        key={row.serialNumber}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" style={{ padding: '5px 15px' }}>
                                            {row.serialNumber}
                                        </TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.onBoardingID}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.sailorFrom}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.sailorTo}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.shipType}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.IMoNumber}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{<Moment format="DD/MM/YYYY">
                                  {row.endDate}
                                  </Moment>}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}>{row.status}</TableCell>
                                        <TableCell align="center" style={{ padding: '5px 15px' }}> 
                                        {/* <Link to="/AddSailorhistory" onClick={()=>{editAgent(row)}}><EditIcon /></Link>   */}
                                        {/* <Link to="/AddSailorhistory"><EditIcon onClick={() => {editAgent(row._id)}} /></Link>  */}
                                        {/* <Link to={'AddSailorhistory/'+row._id}><EditIcon /> </Link> */}
                                        <Link to="/UpdateSailor" onClick={()=>{editAgent(row)}}><EditIcon /></Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>





        </>
    );
}

export default Sailorhistory;